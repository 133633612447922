import client from "../client";

export interface GetDepositAmountRequest {
  transaction_id?: string;
}

export interface GetDepositAmountResponse {
  Amount?: number;
}

export default function API(
  request: GetDepositAmountRequest
): Promise<GetDepositAmountResponse> {
  return client
    .get(`payment/get_deposit_amount`, { params: request })
    .then((response) => response.data);
}
