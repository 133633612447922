import client from "../client";
import { store } from "../../store";
import { memberActionTypes } from "lib/store/member/reducer";

export interface GetVenderBankAccountFromMemberByIDResponse {
  VenderID?: number;
  Username?: string;
  BankNumber?: string;
  BankName?: string;
  Image?: string;
  Color?: string;
  BankAccount?: string;
  VenderIDBankAccount?: number;
}

export default function API(): Promise<GetVenderBankAccountFromMemberByIDResponse> {
  return client
    .get(`members/get_vender_bank_account_from_member_by_id`)
    .then((response) => response.data)
    .then((json: GetVenderBankAccountFromMemberByIDResponse) => {
      store.dispatch({
        type: memberActionTypes.ACTION_USER_UPDATE_BANK_VENDER,
        payload: json,
      });
      return json;
    });
}
