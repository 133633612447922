import client from "../client";
import { store } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";

export interface GetAllGamesSQLAllVender {
  VenderName?: string;
  Results?: GetAllGamesResponseResult[];
}

export enum GameTypeID {
  none,
  slot,
  liveCasino,
  table,
  cashier,
  sceratchGame,
  unknow,
  bingo,
  fishing,
  fighting,
  cashiergame,
  sportbook,
  virtualsportbook,
  event,
  lotto,
  tournament,
  dozer,
}

export interface GetAllGamesResponseResult {
  kind: "GetAllGamesResponseResult";
  ID: number;
  NameEN?: string;
  NameTH?: string;
  GameTypeID: GameTypeID;
  GameTypeName?: null | string;
  Symbol?: string;
  SymbolOriginal?: string;
  Lines?: string | null;
  Poppular?: number;
  StartGameHit?: number;
  Demo?: boolean;
  IsMonth?: boolean;
  IsDay?: boolean;
  Jeckpot?: boolean;
  Disable?: boolean;
  IsNewGame?: boolean;
  IsReSpin?: boolean;
  Content?: null;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DisplayCreatedAt?: string;
  DisplayUpdatedAt?: string;
  Img?: string;
  ImgRec?: string;
  ImgSquare?: string;
  VenderName?: string;
  VenderPrepix?: string;
  SortID?: number;
}

export default function API(): Promise<GetAllGamesResponseResult[]> {
  return client
    .get(`genaral/get_all_game`)
    .then((response) => response.data)
    .then((json) => {
      store.dispatch({
        type: memberActionTypes.ACTION_UPDATE_ALL_GAME,
        payload: json,
      });
      return json;
    });
}
