import axios from "axios";
import { store } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";
import Router from "next/router";
import { getCookie, removeCookies, checkCookies } from "cookies-next";
import global from "../../config/global";

export var { tokenBeare, prefix } = global().gobalVariable;

export const client = axios.create({
  baseURL: process.env.HOST,
  headers: {},
});

client.interceptors.request.use((config) => {
  if (checkCookies("access_token")) {
    tokenBeare = getCookie("access_token")?.toString() ?? "";
  }

  if (!process.browser) {
    return config;
  }

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${tokenBeare}`,
  };

  const uuid = uuidv4();

  config.params = {
    ...config.params,
    uuid: uuid,
    hashcode: hashcode(uuid),
  };

  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (!process.browser) {
      return error;
    }

    if (error.response == null) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      removeCookies("access_token");
      store.dispatch({
        type: memberActionTypes.ACTION_USER_LOGOUT,
      });
      Router.push(`/`);
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export function hashcode(code: string) {
  var md5 = require("md5");
  const b = [...code].sort((a, b) => a.localeCompare(b)).join("");
  return md5(b);
}

export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default client;
