import client from "../client";
import { GetInfoResponse } from "./getinfo";
import { store } from "../../store";
import { memberActionTypes } from "lib/store/member/reducer";

export default function API(): Promise<GetInfoResponse> {
  return client
    .get(`members/getinfo`)
    .then((response) => response.data)
    .then((json) => {
      store.dispatch({
        type: memberActionTypes.ACTION_USER_UPDATE_INFO,
        payload: json,
      });
      return json;
    });
}
