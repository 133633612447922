import { AppReducerStateProps } from "lib/store";
import React from "react";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";

interface SMPointViewProps {
  state: AppReducerStateProps;
}

function SMPointView(props: SMPointViewProps) {
  return (
    <div className="text-center">
      <div className="btn-group">
        <div>
          <div style={{ float: "left", width: 30, height: 30 }}>
            <Img ratio={1 / 1} src="icon-udiamond-new.png" alt="uDiamond"></Img>
          </div>
          <div
            style={{
              marginTop: 3,
              marginLeft: 5,
              float: "right",
            }}
          >
            {props.state.member.getinfo.SMPoint?.format(2, 0)}&nbsp;
          </div>
          <div style={{ clear: "both" }}></div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(SMPointView);
