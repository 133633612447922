import client from "../client";
import { GetAllGamesResponseResult } from "./get_all_game";

interface Request {
  prefix: string;
}

export default function API(
  request: Request
): Promise<GetAllGamesResponseResult[]> {
  return client
    .get(`members/get_all_game_promotion_only`, { params: request })
    .then((response) => response.data)
    .then((json) => {
      return json;
    });
}
