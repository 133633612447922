import { AppReducerStateProps, store } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

interface SuccessModalProps {
  closeModal: Function;
  state: AppReducerStateProps;
}

export const alertWithSuccessMessage = (message: string) => {
  store.dispatch({
    type: memberActionTypes.ACTION_OPEN_MODAL_DEPOSIT_SUCCESS,
    payload: message,
  });
};

function SuccessModal(props: SuccessModalProps) {
  const { closeModal } = props;
  const { isModalDepositSuccess, messageSuccessModal } = props.state.member;
  return (
    <Modal
      {...props}
      size="lg"
      scrollable={true}
      contentClassName="view-background-black"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalDepositSuccess}
      onHide={() => {
        closeModal();
      }}
      dialogClassName="modal-alert"
    >
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="example-modal-sizes-title-lg">
          <div className="text-title center-row-content font-bold">
            <span>แจ้งเตือน</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="row center-row-content">{messageSuccessModal}</div>
          <div className="success-checkmark margin-top-30">
            <div className="check-icon">
              <span className="icon-line line-tip"></span>
              <span className="icon-line line-long"></span>
              <div className="icon-circle"></div>
              <div className="icon-fix"></div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className="row">
          <div
            className="btn btn-purple-submit"
            onClick={() => {
              closeModal();
            }}
          >
            ปิด
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModal: () => {
      dispatch({
        type: memberActionTypes.ACTION_OPEN_MODAL_DEPOSIT_CLOSE,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessModal);
