import client from "../client";

export interface GetAllContentByPrefixRequest {
  prefix: string;
}

export interface GetAllContentByPrefixResponse {
  Html?: string;
  Title?: string;
  Despication?: string;
  Alt?: string;
  Link?: string;
  ID?: number;
  Type?: string;
  ContentTypeID?: number;
}

export default function API(
  request: GetAllContentByPrefixRequest
): Promise<GetAllContentByPrefixResponse[]> {
  return client
    .get(`content/get_all_content_by_prefix`, { params: request })
    .then((response) => response.data)
    .then((json: GetAllContentByPrefixResponse[]) => {
      return json;
    });
}
