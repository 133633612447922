import client from "../client";

export interface NotificationDepositToVenderRequest {
  bank_member_Id: number;
  transaction_id?: string;
  promotion_type_id?: number;
}

export default function API(request: NotificationDepositToVenderRequest) {
  return client
    .post(`payment/notification_deposit_to_vender`, request)
    .then((response) => response.data);
}
