import get_transaction_get_member_list_of_deposit_money from "lib/worker/api/get_transaction_get_member_list_of_deposit_money";
import getinfoapi from "lib/worker/api/getinfo";
import Pusher from "pusher-js";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { AppReducerStateProps } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";
import { alertWithDepositClose } from "components/modal/deposit_modal";

export const pusher = new Pusher(process.env.PUSHER_KEY ?? "", {
  cluster: "ap1",
  authEndpoint: process.env.HOST + "/auth/pusher/auth",
});

interface PushDepositEvent {
  state: AppReducerStateProps;
  depositSuccess: Function;
  depositReject: Function;
}

function PushDepositEvent(props: PushDepositEvent) {
  const { getinfo, isLogin } = props.state.member;
  const { depositSuccess, depositReject } = props;
  useEffect(() => {
    if (!isLogin) {
      return;
    }

    const channelDeposit = pusher.subscribe(
      "private-client-my-channel-deposit-success-" + getinfo.Username
    );
    const channelReject = pusher.subscribe(
      "private-client-my-channel-deposit-reject-" + getinfo.Username
    );

    setTimeout(() => {
      channelDeposit.bind(
        `deposit-success${getinfo.Username ?? ""}`,
        (data: any) => {
          get_transaction_get_member_list_of_deposit_money();
          getinfoapi();

          if (data == "first-time") {
            depositSuccess(
              "คุณได้รับโบนัสเติมเงินครั้งแรกของวัน 1,000 uDiamond"
            );
          } else {
            depositSuccess(`เติมเงินสำเร็จ เป็นจำนวน: ${data} เครดิต`);
          }

          alertWithDepositClose();
        }
      );

      channelReject.bind(`deposit-reject${getinfo.Username ?? ""}`, () => {
        get_transaction_get_member_list_of_deposit_money();
        getinfoapi();
        depositReject();
      });
    }, 1000);

    return () => {
      channelDeposit.unbind_all();
      channelDeposit.disconnect();
      channelReject.unbind_all();
      channelReject.disconnect();
    };
  }, [getinfo]);

  return <></>;
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    depositSuccess: (message: string) => {
      dispatch({
        type: memberActionTypes.ACTION_OPEN_MODAL_DEPOSIT_SUCCESS,
        payload: message,
      });
    },
    depositReject: () => {
      dispatch({
        type: memberActionTypes.ACTION_OPEN_MODAL_DEPOSIT_REJECT,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PushDepositEvent);
