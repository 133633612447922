export interface TimeState {
  diff?: number;
  ms?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

export function seconds2time(seconds: number) {
  var hours = Math.floor(seconds / 3600);
  var minutes: any = Math.floor((seconds - hours * 3600) / 60);
  var seconds = seconds - hours * 3600 - minutes * 60;
  var time = "";

  if (hours != 0) {
    time = hours.format(0, 0) + ":";
  }
  if (minutes != 0 || time !== "") {
    minutes =
      minutes < 10 && time !== ""
        ? "0" + minutes.format(0, 0)
        : String(minutes.format(0, 0));
    time += minutes + ":";
  }
  if (time === "") {
    time = seconds + "s";
  } else {
    time +=
      seconds < 10 ? "0" + seconds.format(0, 0) : String(seconds.format(0, 0));
  }
  return time;
}

export function dateDiff(dStr1: Date, dStr2: Date): TimeState {
  var diff = dStr2.getTime() - dStr1.getTime();
  return isNaN(diff)
    ? {}
    : {
        diff: diff,
        ms: Math.floor(diff % 1000),
        seconds: Math.floor((diff / 1000) % 60),
        minutes: Math.floor((diff / 60000) % 60),
        hours: Math.floor((diff / 3600000) % 24),
        days: Math.floor(diff / 86400000),
      };
}

export function hoursStringToDecimal(hoursString: string) {
  const [hoursPart, minutesPart] = hoursString.split(":");
  return Number(hoursPart) + Number(minutesPart) / 60;
}

export function decimalHoursToString(hoursDecimal: number) {
  const numHours = Math.floor(hoursDecimal);
  const numMinutes = Math.round((hoursDecimal - numHours) * 60);
  return `${numHours < 10 ? "0" : ""}${numHours} : ${
    numMinutes < 10 ? "0" : ""
  }${numMinutes}`;
}

export function dateDiffOfString(str1: string, str2: string): TimeState {
  var dStr1 = new Date(str1);
  var dStr2 = new Date(str2);
  var diff = dStr2.getTime() - dStr1.getTime();
  return isNaN(diff)
    ? {}
    : {
        diff: diff,
        ms: Math.floor(diff % 1000),
        seconds: Math.floor((diff / 1000) % 60),
        minutes: Math.floor((diff / 60000) % 60),
        hours: Math.floor((diff / 3600000) % 24),
        days: Math.floor(diff / 86400000),
      };
}

export const calculateTimeLeft = (timeAt: string): TimeState => {
  let create = new Date(timeAt ?? "");
  let now = new Date();
  let timeLeft: TimeState = {};
  let plus30Min = new Date(create.getTime() + 30 * 60000).toString();
  const diff = dateDiffOfString(now.toString(), plus30Min);
  if ((diff.minutes ?? 0) < 0) {
    timeLeft = {
      diff: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  } else {
    timeLeft = {
      diff: diff.diff,
      days: diff.days,
      hours: diff.hours,
      minutes: diff.minutes,
      seconds: diff.seconds,
    };
  }

  return timeLeft;
};
