import { ChangeEventHandler, MouseEventHandler } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import ChatSelectImgModal from "components/modal/chat_select_image_modal";
import EuiFormControl from "@mui/material/FormControl";
import {
  Button,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";

interface TextFieldFromProps {
  title?: string;
  value?: string | number | string[] | undefined;
  image?: string | undefined;
  disable?: boolean;
  prefix?: string | number | string[] | undefined;
  type?: string;
  onChange?: React.ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >;
  onImageChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  titleButton?: string;
  onClick?: MouseEventHandler;
  values?: TextFieldFromSelectedArrayType[] | undefined;
  closeModal?: Function | undefined;
  isModalChatImage?: boolean | undefined;
  className?: string;
  children?: React.ReactNode;
  despication?: string;
  name?: string;
  disableButton?: boolean;
}

export interface TextFieldFromSelectedArrayType {
  id: string | undefined;
  value: string | number | readonly string[] | undefined;
  object: any | undefined;
}

export function LeftTextFieldFrom(props: TextFieldFromProps) {
  return (
    <Form.Group>
      <Form.Label>{props.title}</Form.Label>
      <Form.Control
        disabled={props.disable}
        value={props.value}
        type={props.type}
        onChange={props.onChange}
      ></Form.Control>
    </Form.Group>
  );
}

export function LeftTextFieldFromNumberOnly(props: TextFieldFromProps) {
  return (
    <Form.Group>
      <Form.Label>{props.title}</Form.Label>
      <Form.Control
        pattern="[0-9]*"
        value={props.value}
        type={props.type}
        onChange={props.onChange}
      ></Form.Control>
    </Form.Group>
  );
}
// Prepend
export function LeftHavePrefixTextFieldFrom(props: TextFieldFromProps) {
  return (
    <Form.Group>
      <Form.Label>{props.title}</Form.Label>
      <InputGroup>
        <InputGroup.Text>{props.prefix}</InputGroup.Text>
        <FormControl
          id="inlineFormInputGroup"
          type={props.type}
          placeholder={props.title}
          value={props.value}
          onChange={props.onChange}
        />
      </InputGroup>
    </Form.Group>
  );
}

export function LeftHaveNoPrefix(props: TextFieldFromProps) {
  return (
    <Form.Group>
      <InputGroup>
        <FormControl
          id="inlineFormInputGroup"
          type={props.type}
          placeholder={props.title}
          value={props.value}
          onChange={props.onChange}
        />
      </InputGroup>
    </Form.Group>
  );
}

export function TextFieldFromSendButton(props: TextFieldFromProps) {
  return (
    <Form.Group>
      <Form.Label>{props.title}</Form.Label>
      <InputGroup style={{ padding: 0 }}>
        <Form.Control
          value={props.value}
          type={props.type}
          onChange={props.onChange}
          disabled={props.disable}
        ></Form.Control>
        <Form.Text>
          <button
            className={`btn btn-purple-submit-subfix`}
            style={{ marginTop: -3 }}
            onClick={props.onClick}
          >
            {props.titleButton}
          </button>
        </Form.Text>
      </InputGroup>
    </Form.Group>
  );
}

export function TextFieldFromSendChat(props: TextFieldFromProps) {
  return (
    <div style={{ padding: 10 }}>
      <ChatSelectImgModal
        closeModal={props.closeModal}
        isModalChatImage={props.isModalChatImage ?? false}
        image={props.image}
        onSendImage={props.onClick}
      ></ChatSelectImgModal>
      <input
        style={{ display: "none" }}
        id="file-input"
        type="file"
        onClick={(event) => {
          const element = event.target as HTMLInputElement;
          element.value = "";
        }}
        onChange={props.onImageChange}
      ></input>
      <Form.Group>
        <InputGroup>
          <label htmlFor="file-input">
            <img
              style={{ width: 40, height: 40 }}
              src="/assets/img-icon-violet.png"
            ></img>
          </label>
          <Form.Control
            className="bg-color-chat-field"
            style={{
              borderRadius: 10,
              marginRight: 10,
              marginLeft: 10,
              height: 40,
            }}
            value={props.value}
            type={props.type}
            onChange={props.onChange}
          ></Form.Control>
          <button
            style={{ borderRadius: 7, height: 40 }}
            className="btn btn-purple-chat"
            onClick={props.onClick}
          >
            {props.titleButton}
          </button>
        </InputGroup>
      </Form.Group>
    </div>
  );
}

export function TextFieldFromSelect(props: TextFieldFromProps) {
  return (
    <Form.Group>
      <Form.Label>{props.title}</Form.Label>
      <InputGroup>
        <Form.Control as="select" type={props.type} onChange={props.onChange}>
          {(props.values ?? []).map((value, i) => {
            return (
              <option key={i} id={value.id} value={value.object}>
                {value.value}
              </option>
            );
          })}
        </Form.Control>
      </InputGroup>
    </Form.Group>
  );
}

export function MuiTextFieldFrom(props: TextFieldFromProps) {
  return (
    <div className={props.className}>
      <EuiFormControl fullWidth>
        <TextField
          name={props.name}
          label={props.title}
          value={props.value}
          disabled={props.disable}
          type={props.type}
          onChange={props.onChange}
          InputProps={{
            startAdornment:
              props.prefix != null ? (
                <Button
                  disabled
                  className="btn-purple-submit"
                  style={{
                    width: 75,
                    marginRight: 5,
                    color: "black",
                    backgroundColor: "#ffbe00",
                  }}
                >
                  {props.prefix}
                </Button>
              ) : null,
          }}
          sx={{
            input: {
              color: "var(--font_base_color)",
            },
            background: "#000000",
            "& label.MuiInputLabel-root": {
              color: "var(--font_base_color)",
            },
            "& label.Mui-focused": {
              color: "var(--font_base_color)",
            },

            "& .MuiInput-underline:after": {
              borderBottomColor: "#ffbe00",
            },
            "& .MuiFilledInput-underline:after": {
              borderBottomColor: "#ffbe00",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#ffbe00",
              },
              "& fieldset": {
                borderColor: "#ffbe00",
              },
              "&:hover fieldset": {
                borderColor: "#ffbe00",
              },
            },
          }}
        ></TextField>
        {props.despication && (
          <FormHelperText>
            <span>{props.despication}</span>
          </FormHelperText>
        )}
      </EuiFormControl>
    </div>
  );
}

export function MuiTextFieldFromSelect(props: TextFieldFromProps) {
  return (
    <div className={props.className}>
      <EuiFormControl fullWidth>
        <InputLabel
          id="demo-simple-selectmui label"
          sx={{ color: "var(--font_base_color)" }}
        >
          {props.title}
        </InputLabel>
        <Select
          label={props.title}
          sx={{
            color: "var(--font_base_color)",
            background: "#000000",
            "&.MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#ffbe00",
              },
              "&:hover fieldset": {
                borderColor: "#ffbe00",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#ffbe00",
              },
              "& .MuiSvgIcon-root": {
                color: "var(--font_base_color)",
              },
            },
          }}
        >
          {props.children}
        </Select>
        {props.despication && (
          <FormHelperText>{props.despication}</FormHelperText>
        )}
      </EuiFormControl>
    </div>
  );
}

export function MuiTextFieldFromWithoutFormControl(props: TextFieldFromProps) {
  return (
    <div className={props.className}>
      <TextField
        fullWidth
        label={props.title}
        value={props.value}
        disabled={props.disable}
        type={props.type}
        onChange={props.onChange}
        InputProps={{
          startAdornment:
            props.prefix != null ? (
              <Button
                disabled
                className="btn-purple-submit"
                style={{ width: 75, marginRight: 5, color: "white" }}
              >
                {props.prefix}
              </Button>
            ) : null,
        }}
        sx={{
          input: {
            color: "var(--font_base_color)",
          },
          background: "#000000",
          "& label.MuiInputLabel-root": {
            color: "var(--font_base_color)",
          },
          "& label.Mui-focused": {
            color: "var(--font_base_color)",
          },

          "& .MuiInput-underline:after": {
            borderBottomColor: "#ffbe00",
          },
          "& .MuiFilledInput-underline:after": {
            borderBottomColor: "#ffbe00",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#ffbe00",
            },
            "& fieldset": {
              borderColor: "#ffbe00",
            },
            "&:hover fieldset": {
              borderColor: "#ffbe00",
            },
          },
        }}
      ></TextField>
      {props.despication && (
        <FormHelperText>
          <span>{props.despication}</span>
        </FormHelperText>
      )}
    </div>
  );
}

export function MuiTextFieldFromSendButton(props: TextFieldFromProps) {
  return (
    <div className={props.className}>
      <EuiFormControl fullWidth>
        <TextField
          InputProps={{
            startAdornment:
              props.prefix != null ? (
                <Button
                  disabled
                  style={{ width: 90, color: "black", background: "#ffbe00" }}
                  onClick={props.onClick}
                >
                  {props.prefix}
                </Button>
              ) : null,

            endAdornment: (
              <Button
                disabled={props.disableButton ?? false}
                className={
                  props.disableButton ? "btn-purple-close" : "btn-purple-submit"
                }
                style={{
                  width: 90,
                  color: props.disableButton ? "grey" : "white",
                }}
                onClick={props.onClick}
              >
                {props.titleButton}
              </Button>
            ),
          }}
          label={props.title}
          value={props.value}
          disabled={props.disable}
          type={props.type}
          onChange={props.onChange}
          sx={{
            input: {
              color: "var(--font_base_color)",
            },
            background: "#000000",
            "& label.MuiInputLabel-root": {
              color: "var(--font_base_color)",
            },
            "& label.Mui-focused": {
              color: "var(--font_base_color)",
            },

            "& .MuiInput-underline:after": {
              borderBottomColor: "#ffbe00",
            },
            "& .MuiFilledInput-underline:after": {
              borderBottomColor: "#ffbe00",
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#ffbe00",
              },
              "& fieldset": {
                borderColor: "#ffbe00",
              },
              "&:hover fieldset": {
                borderColor: "#ffbe00",
              },
            },
          }}
        ></TextField>
        {props.despication && (
          <FormHelperText>{props.despication}</FormHelperText>
        )}
      </EuiFormControl>
    </div>
  );
}
