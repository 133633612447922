import client from "../client";

export interface NotificationWithDrawToVenderRequest {
  bank_member_Id: number;
  amount: number;
}

export default function API(request: NotificationWithDrawToVenderRequest) {
  return client
    .post(`payment/notification_withdraw_to_vender`, request)
    .then((response) => response.data);
}
