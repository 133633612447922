import { AppReducerStateProps } from "lib/store";
import Link from "next/link";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";
import NavBlance from "./nav_balance";
import NavLoginView from "./nav_login_view";
import { useCookies } from "react-cookie";
import global from "lib/config/global";
import MenuTabBar from "./menu_tab_bar";
import {
  CheckWithPrefix,
  CheckWithPrefixResponse,
  PREFIX_SNAMBET,
} from "lib/infomation_vender/document";
import { useEffect, useState } from "react";

interface NavBarProps {
  state: AppReducerStateProps;
  infomation: CheckWithPrefixResponse;
}

const NavBar = (props: NavBarProps) => {
  const [cookie] = useCookies(["access_token"]);
  const { logo, prefix } = global().gobalVariable;
  const [, setinformation] = useState<CheckWithPrefixResponse>();

  useEffect(() => {
    const infomations = CheckWithPrefix({ host: props.infomation.domain });
    setinformation(infomations);
  }, []);

  const LogoPrefix = () => {
    switch (props.infomation.prefix) {
      case PREFIX_SNAMBET:
        return (
          <>
            <div style={{ float: "left" }}>
              <Link href="/">
                <a>
                  <div
                    className="logo-snam"
                    style={{ marginLeft: 40, marginTop: 10 }}
                  >
                    <Img
                      ratio={3.5 / 1}
                      className="select-div"
                      src="logo-ufabet-snam.png"
                      alt="logo ufabet snambet"
                    ></Img>
                  </div>
                </a>
              </Link>
            </div>
          </>
        );
      default:
        return (
          <>
            <div style={{ float: "left" }}>
              <Link href="/">
                <a>
                  <div
                    className="logo-snam"
                    style={{ marginLeft: 40, marginTop: 10 }}
                  >
                    <Img
                      ratio={3.5 / 1}
                      className="select-div"
                      src={logo}
                      alt={prefix}
                    ></Img>
                  </div>
                </a>
              </Link>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <nav className="navbar fixed-top bg-nav-background-color">
        <MenuTabBar></MenuTabBar>
        <div className="flex-grow-1 nav-align-layout">
          {LogoPrefix()}
          <div style={{ float: "right" }}>
            {cookie.access_token == null && (
              <NavLoginView information={props.infomation}></NavLoginView>
            )}
            {cookie.access_token != null && <NavBlance></NavBlance>}
          </div>
        </div>
      </nav>
    </>
  );
};

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(NavBar);
