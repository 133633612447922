import client from "../client";

export interface GetStatusMemberResponse {
  IsFreeCredit: boolean;
}

export default function API(): Promise<GetStatusMemberResponse> {
  return client
    .get(`members/get_status_member`, {})
    .then((response) => response.data)
    .then((json: GetStatusMemberResponse) => {
      return json;
    });
}
