import client from "../client";

interface Request {
  hash_key: string;
}

export default function API(request: Request): Promise<any> {
  return client
    .post(`payment/cancel_deposit_with_member`, request)
    .then((response) => response.data);
}
