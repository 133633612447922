import client from "../client";
import { store } from "../../store";
import { memberActionTypes } from "lib/store/member/reducer";
import { LoginResponse } from "./login";
import { setCookies } from "cookies-next";
import { TIME_COOKIES } from "lib/config/global";

export interface RefreshTokenRequest {
  refresh_token: string;
}

export interface RefreshTokenResponse {
  access_token?: string;
  refresh_token?: string | null;
}

export default function API(
  request: RefreshTokenRequest
): Promise<LoginResponse> {
  return client
    .post(`auth/refresh_token`, request)
    .then((response) => response.data)
    .then((json: LoginResponse) => {
      setCookies("access_token", json.access_token, {
        maxAge: TIME_COOKIES,
      });
      setCookies("refresh_token", json.refresh_token, {
        maxAge: TIME_COOKIES,
      });
      localStorage.setItem("access_token", json.access_token ?? "");
      store.dispatch({
        type: memberActionTypes.ACTION_USER_LOGIN,
        payload: json,
      });
      return json;
    });
}

export function APIRefreshTokenExternal(
  request: RefreshTokenResponse
): Promise<RefreshTokenResponse> {
  return client
    .post(`auth/refresh_token`, request)
    .then((response) => response.data)
    .then((json: RefreshTokenResponse) => {
      return json;
    });
}
