import client from "../client";
import { store } from "../../store";
import { memberActionTypes } from "lib/store/member/reducer";

export interface GetTransactionByGameIDTotalAmountGetWinRequest {
  gameID?: number;
}

export interface GetTransactionByGameIDTotalAmountGetWinResponse {
  AfterBetAmount: number;
  IsFavorite: boolean;
}

export default function API(
  request: GetTransactionByGameIDTotalAmountGetWinRequest
): Promise<GetTransactionByGameIDTotalAmountGetWinResponse> {
  return client
    .get(`members/get_transaction_by_game_id_total_amount_get_win`, {
      params: request,
    })
    .then((response) => response.data)
    .then((json: GetTransactionByGameIDTotalAmountGetWinResponse) => {
      store.dispatch({
        type: memberActionTypes.ACTION_GET_AMOUNT_TOTAL_WIN_BY_GAME,
        payload: json,
      });
      return json;
    });
}
