import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";
import Link from "next/link";
import { AppReducerStateProps } from "../../lib/store";
import { alertWithDeposit } from "components/modal/deposit_modal";
import { alertWithWithDraw } from "components/modal/withdraw_modal";
import start_game from "lib/worker/api/start_game";
import { CheckWithPrefixResponse } from "lib/infomation_vender/document";

interface Props {
  state: AppReducerStateProps;
  infomation: CheckWithPrefixResponse;
}

function NavBottomNavigation(_props: Props) {
  return (
    <>
      <div className="nav" style={{ paddingBottom: 20 }}>
        <Link href="/">
          <div className="nav__link font-nav" style={{ cursor: "pointer" }}>
            <div className="icon-dropdown-navigation">
              <Img ratio={1 / 1} src="icon-home-ufabet.png" alt="หน้าแรก"></Img>
            </div>
            หน้าแรก
          </div>
        </Link>

        <div
          className="nav__link font-nav"
          style={{ cursor: "pointer" }}
          onClick={() => {
            start_game({
              VenderPrepix: "ufabet",
              Symbol: "ufabet",
            });
          }}
        >
          <div className="icon-dropdown-navigation">
            <Img ratio={1 / 1} src="ufaber1-foot-icon.png" alt="เล่น"></Img>
          </div>
          เข้าเล่น
        </div>

        <div
          className="nav__link font-nav"
          style={{ cursor: "pointer" }}
          onClick={() => {
            alertWithDeposit();
          }}
        >
          <div className="icon-dropdown-navigation">
            <Img ratio={1 / 1} src="ufabet-savecash.png" alt="ฝากเงิน"></Img>
          </div>
          ฝากเงิน
        </div>

        <div
          className="nav__link font-nav"
          style={{ cursor: "pointer" }}
          onClick={() => {
            alertWithWithDraw();
          }}
        >
          <div className="icon-dropdown-navigation">
            <Img ratio={1 / 1} src="ufabet-bank.png" alt="ถอนเงิน"></Img>
          </div>
          ถอนเงิน
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(NavBottomNavigation);
