import { CSSProperties, MouseEventHandler } from "react";
import Img from "react-cloudimage-responsive";

interface OptimizeImageProps {
  className: string;
  src: string;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  style?: CSSProperties;
  alt?: string;
}

export default function OptimizeImage(props: OptimizeImageProps) {
  return (
    <div onClick={props?.onClick} style={{ ...props.style }}>
      <Img
        className={props.className}
        src={`${props.src}`}
        alt={props.alt}
      ></Img>
    </div>
  );
}
