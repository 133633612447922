import { MaxButton, StatusMaxButton } from "components/view/button";
import { AppReducerStateProps, store } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";
import { useRouter } from "next/router";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { removeCookies } from "cookies-next";

interface CheckOutModalProps {
  state: AppReducerStateProps;
  closeModal: Function;
}

export const alertCheckOutModal = () => {
  store.dispatch({
    type: memberActionTypes.ACTION_OPEN_MODAL_CHECK_OUT,
  });
};

function CheckOutModal(props: CheckOutModalProps) {
  const { closeModal } = props;
  const { isModalCheckOut } = props.state.member;
  const router = useRouter();

  return (
    <Modal
      {...props}
      size="lg"
      scrollable={true}
      contentClassName="view-background-black"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalCheckOut}
      onHide={() => {
        closeModal();
      }}
      dialogClassName="modal-alert"
    >
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="example-modal-sizes-title-lg">
          <div className="text-title center-row-content text-bold">
            <span>ออกจากระบบ</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <MaxButton
            title="ยืนยัน"
            status={StatusMaxButton.submit}
            onClick={() => {
              closeModal();
              removeCookies("access_token");
              removeCookies("refresh_token");
              localStorage.removeItem("guest");
              router.reload();
            }}
          ></MaxButton>
          <MaxButton
            className="margin-top-10"
            title="ปิด"
            status={StatusMaxButton.close}
            onClick={() => {
              closeModal();
            }}
          ></MaxButton>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModal: () => {
      dispatch({
        type: memberActionTypes.ACTION_CLSE_MODAL_CHECK_OUT,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutModal);
