import client from "../client";
import { store } from "../../store";
import { memberActionTypes } from "lib/store/member/reducer";
import { THEME_COLOR } from "lib/config/global";

export interface ThemeColorRequest {
  prefix: string;
  themeColor?: string | null;
}

export interface ThemeColorResponse {
  BackgroundCampgameColor?: string;
  BackgroundCardColor?: string;
  BackgroundChatBottom?: string;
  BackgroundChatField?: string;
  BackgroundChatSend?: string;
  BackgroundChatVender?: string;
  BackgroundDropdownColor?: string;
  BackgroundDropdownColorHover?: string;
  BackgroundNavColor?: string;
  BackgroundWebsiteColor?: string;
  BorderButtonColor?: string;
  BorderInfoColor?: string;
  ButtonBack?: string;
  ButtonClose?: string;
  ButtonLogin?: string;
  ButtonRegister?: string;
  ButtonSubmitOk?: string;
  ButtonSubmitUgames?: string;
  ButtonSubmitWithdraw?: string;
  FontBaseColor?: string;
  FontButtonCloseColor?: string;
  FontButtonHoverColor?: string;
  FontButtonLoginColor?: string;
  FontButtonRegiterColor?: string;
  FontButtonSubmitOkColor?: string;
  FontButtonSubmitUgamesColor?: string;
  FontButtonSubmitWithdrawColor?: string;
  FontCardColor?: string;
  FontChatSendColor?: string;
  FontChatVenderColor?: string;
  FontSpanColor?: string;
}

export default function API(request: ThemeColorRequest): Promise<string> {
  if (request.themeColor != null) {
    store.dispatch({
      type: memberActionTypes.ACTION_UPDATE_THEME_COLOR,
      payload: request.themeColor,
    });
  }

  return client
    .get(`genaral/theme_color`, { params: { prefix: request.prefix } })
    .then((response) => response.data)
    .then((theme) => {
      const payload: string = `
:root {
  /* Background Color */
  --background_website_color: ${theme.BackgroundWebsiteColor};
  --background_card_color: ${theme.BackgroundCardColor};
  --background_nav_color: ${theme.BackgroundNavColor};
  --background_campgame_color: ${theme.BackgroundCampgameColor};
  --background_dropdown_color: ${theme.BackgroundDropdownColor};
  --background_dropdown_color_hover: ${theme.BackgroundDropdownColorHover};
  --background_chat_send: ${theme.BackgroundChatSend};
  --background_chat_vender: ${theme.BackgroundChatVender};
  --background_chat_bottom: ${theme.BackgroundChatBottom};
  --background_chat_field: ${theme.BackgroundChatField};
  /* Button Color */
  --button_submit_ok: ${theme.ButtonSubmitOk};
  --button_submit_withdraw: ${theme.ButtonSubmitWithdraw};
  --button_submit_ugames: ${theme.ButtonSubmitUgames};
  --button_close: ${theme.ButtonClose};
  --button_back: ${theme.ButtonBack};
  --button_login: ${theme.ButtonLogin};
  --button_register: ${theme.ButtonRegister};
  --border_button_color: ${theme.BorderButtonColor};
  --border_info_color: ${theme.BorderInfoColor} !important;
  /* Font Color */
  --font_base_color: ${theme.FontBaseColor} !important;
  --font_card_color: ${theme.FontCardColor};
  --font_span_color: ${theme.FontSpanColor};
  --font_button_submit_ok_color: ${theme.FontButtonSubmitOkColor};
  --font_button_submit_withdraw_color: ${theme.FontButtonSubmitWithdrawColor};
  --font_button_submit_ugames_color: ${theme.FontButtonSubmitUgamesColor};
  --font_button_login_color: ${theme.FontButtonLoginColor};
  --font_button_regiter_color: ${theme.FontButtonRegiterColor};
  --font_button_close_color: ${theme.FontButtonCloseColor};
  --font_button_hover_color: ${theme.FontButtonHoverColor};
  --font_chat_send_color: ${theme.FontChatSendColor};
  --font_chat_vender_color: ${theme.FontChatVenderColor};
}
`;

      store.dispatch({
        type: memberActionTypes.ACTION_UPDATE_THEME_COLOR,
        payload: payload,
      });

      localStorage.setItem(THEME_COLOR, payload);
      return payload;
    });
}
