import {
  TimeState,
  decimalHoursToString,
  hoursStringToDecimal,
} from "lib/time";
import get_transaction_get_member_list_of_deposit_money, {
  GetTransactionGetMemberListOfDepositMoneyRepsonse,
} from "lib/worker/api/get_transaction_get_member_list_of_deposit_money";
import { useEffect, useState } from "react";
import { connect } from "react-redux";

interface TimerProps {
  isLogin: boolean;
  transactionDeposit: GetTransactionGetMemberListOfDepositMoneyRepsonse[];
}

function TimerCheckin(props: TimerProps) {
  const { isLogin, transactionDeposit } = props;

  const calculateTimeLeft = (): TimeState => {
    let create = new Date(transactionDeposit[0].CreatedAt ?? "");
    let now = new Date();
    let timeLeft: TimeState = {};
    let plus30Min = new Date(create.getTime() + 30 * 60000).toString();
    const diff = dateDiff(now.toString(), plus30Min);

    if ((diff.minutes ?? 0) < 0) {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    } else {
      timeLeft = {
        days: diff.days,
        hours: diff.hours,
        minutes: diff.minutes,
        seconds: diff.seconds,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeState>(calculateTimeLeft());

  useEffect(() => {
    if (isLogin) {
      get_transaction_get_member_list_of_deposit_money();
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  function dateDiff(str1: string, str2: string): TimeState {
    var dStr1 = new Date(str1);
    var dStr2 = new Date(str2);
    var diff = dStr2.getTime() - dStr1.getTime();
    return isNaN(diff)
      ? {}
      : {
          diff: diff,
          ms: Math.floor(diff % 1000),
          seconds: Math.floor((diff / 1000) % 60),
          minutes: Math.floor((diff / 60000) % 60),
          hours: Math.floor((diff / 3600000) % 24),
          days: Math.floor(diff / 86400000),
        };
  }

  const sumHors = hoursStringToDecimal(
    `${timeLeft?.minutes?.format(0, 0)}:${timeLeft?.seconds?.format(0, 0)}`
  );

  return (
    <div className="text-center">{decimalHoursToString(sumHors)} นาที</div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    isLogin: state.member.isLogin,
    transactionDeposit: state.member.transactionDeposit,
  };
};

export default connect(mapStateToProps)(TimerCheckin);
