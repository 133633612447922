import { CheckWithPrefixResponse } from "lib/infomation_vender/document";
import Link from "next/link";
import { Modal } from "react-bootstrap";
import Img from "react-cloudimage-responsive";
import Carousel from "better-react-carousel";
import { GetPopupSettingByVenderIDSQL } from "lib/worker/api/get_popup_list_list_on_website";

interface showPopUpModalProps {
  popups: GetPopupSettingByVenderIDSQL[];
  show: boolean;
  handleClose?: Function | undefined;
  infomation: CheckWithPrefixResponse;
}

export default function PopUpModal(props: showPopUpModalProps) {
  const { handleClose, show, popups } = props;

  function PopUpBanner() {
    switch (props.infomation.prefix) {
      case "PREFIX":
        return (
          <Link href="/invite_agent">
            <a>
              <Img
                ratio={0.9 / 1}
                className="full-width-image margin-top-10 border-radius"
                src="Apec888invite2.jpg"
                alt="เชิญเพื่อน"
              ></Img>
            </a>
          </Link>
        );

      default:
        return (
          <>
            <Carousel
              cols={1}
              rows={1}
              gap={10}
              loop
              autoplay={2000}
              showDots
              scrollSnap={true}
              dotColorActive="#ffffff"
              hideArrow={false}
            >
              {popups
                .filter((v) => {
                  return !v.Disable;
                })
                .map((value, i) => {
                  return (
                    <Carousel.Item key={i}>
                      <Img
                        ratio={1 / 1}
                        src={value.Image ?? ""}
                        alt={value.Image ?? ""}
                      ></Img>
                    </Carousel.Item>
                  );
                })}
            </Carousel>
          </>
        );
    }
  }

  return (
    <Modal
      {...props}
      size="sm"
      scrollable={true}
      contentClassName="view-background-popup"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      animation={false}
      onHide={() => {
        if (handleClose) {
          handleClose();
        }
      }}
      dialogClassName="modal-popup"
    >
      <Modal.Body>
        <div style={{ cursor: "pointer" }}>{PopUpBanner()}</div>
        <div
          className="center-row-content"
          style={{
            textAlign: "center",
            marginTop: 20,
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => {
            if (handleClose) {
              handleClose();
            }
          }}
        >
          ไม่ต้องแสดงในวันนี้อีก
        </div>
        <div
          className="center-row-content"
          style={{ textAlign: "center", marginTop: 20, cursor: "pointer" }}
        >
          <button
            style={{
              backgroundColor: "white",
              opacity: 0.9,
              borderRadius: 300,
              padding: 10,
            }}
            type="button"
            onClick={() => {
              if (handleClose) {
                handleClose();
              }
            }}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
