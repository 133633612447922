import { AppReducerStateProps } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";

interface RejectModalProps {
  closeModal: Function;
  state: AppReducerStateProps;
}

function RejectModal(props: RejectModalProps) {
  const { closeModal } = props;
  const { isModalDepositReject } = props.state.member;
  return (
    <Modal
      {...props}
      size="lg"
      scrollable={true}
      contentClassName="view-background-black"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalDepositReject}
      onHide={() => {
        closeModal();
      }}
      dialogClassName="modal-alert"
    >
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="example-modal-sizes-title-lg">
          <div className="text-title center-row-content font-black font-bold">
            แจ้งเตือน
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="row center-row-content font-black">
            เติมเงินไม่สำเร็จ
          </div>
          <div className="swal2-icon swal2-error swal2-animate-error-icon flex-er">
            <span className="swal2-x-mark">
              <span className="swal2-x-mark-line-left"></span>
              <span className="swal2-x-mark-line-right"></span>
            </span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className="row">
          <div
            className="btn btn-purple-submit"
            onClick={() => {
              closeModal();
            }}
          >
            ปิด
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModal: () => {
      dispatch({
        type: memberActionTypes.ACTION_OPEN_MODAL_DEPOSIT_CLOSE,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RejectModal);
