import client from "../client";

interface Request {
  prefix: string;
}

export interface GetPopupSettingByVenderIDSQL {
  ID: number;
  Disable: boolean;
  CreatedAt: string;
  UpdateAt: string;
  Image: string;
  RanageTimeStart: string;
  RanageTimeEnd: string;
  StaftID: number;
  DisplayCreatedAt: string;
  DisplayUpdatedAt: string;
}

export default function API(
  request: Request
): Promise<GetPopupSettingByVenderIDSQL[]> {
  return client
    .get(`members/get_popup_list_list_on_website`, { params: request })
    .then((response) => response.data);
}
