import client from "../client";
import { GetAllGamesResponseResult } from "../api/get_all_game";
import { store } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";

export default function API(): Promise<GetAllGamesResponseResult[]> {
  return client
    .get(`genaral/get_game_vol_hight`)
    .then((response) => response.data)
    .then((json) => {
      store.dispatch({
        type: memberActionTypes.ACTION_UPDATE_GAME_VOL_HIGHT,
        payload: json,
      });
      return json;
    });
}
