import { getCookie } from "cookies-next";

var gobalVariable = {
  tokenBeare: "",
  logo: "",
  prefix: "",
};

export default () => {
  const variable = { gobalVariable };
  variable.gobalVariable.logo = getCookie("logo")?.toString() ?? "";
  variable.gobalVariable.prefix = getCookie("prefix")?.toString() ?? "";
  return variable;
};

export const THEME_COLOR = "theme-color";
export const TIME_COOKIES = 60 * 60 * 24 * 7;

export const ATOKEN = "access_token";
export const RTOKEN = "refresh_token";
