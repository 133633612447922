import React from "react";
import Img from "react-cloudimage-responsive";

interface BankAccountShowProps {
  bankColor?: string | null | undefined;
  bankImage?: string | null | undefined;
  bankName?: string | null | undefined;
  bankAccount?: string | null | undefined;
  bankNumber?: string | null | undefined;
  loading?: boolean | undefined;
  onClickCopy?: any;
}

export default function BankAccountShow(props: BankAccountShowProps) {
  return (
    <div>
      <div
        className=""
        style={{
          backgroundColor: props.bankColor ?? "",
          borderRadius: 5,
          marginTop: 15,
          padding: "10px 00px 20px 0px",
        }}
      >
        <div className="">
          <div className="color-font-card">
            <div>
              <div
                className=""
                style={{
                  width: 33,
                  height: 33,
                  marginRight: -10,
                  marginLeft: 20,
                  marginTop: 5,
                  float: "left",
                }}
              >
                <Img
                  ratio={1}
                  style={{ height: 25, padding: 0, marginTop: 10 }}
                  src={props.bankImage ?? ""}
                  alt="ฝาก"
                ></Img>
              </div>
              <div
                className=""
                style={{
                  width: 140,
                  height: 1,
                  marginRight: -10,
                  marginLeft: 20,
                  marginTop: 0,
                  opacity: 0.3,
                  float: "right",
                }}
              >
                <Img
                  ratio={1}
                  style={{ height: 120, padding: 0, marginTop: 0 }}
                  src={props.bankImage ?? ""}
                  alt="ฝาก"
                ></Img>
              </div>
              <label
                style={{
                  float: "left",
                  marginTop: 15,
                  marginLeft: 10,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                {props.bankAccount}
              </label>
              <div style={{ clear: "both" }}></div>
            </div>
          </div>
          <div className="margin-top-10" style={{ marginLeft: 20 }}>
            <h3 className="card-font-h3">
              <span className="font-white">{props.bankName}</span>
            </h3>
            <h3 className="card-font-bank font-white">{props.bankNumber}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
