import Link from "next/link";
import {
  CheckWithPrefixResponse,
  GetPrefixLevel,
  PREFIX_UFAONLINE1,
} from "lib/infomation_vender/document";

interface Props {
  information: CheckWithPrefixResponse;
}

function NavLoginView(props: Props) {
  const prefix = GetPrefixLevel(props.information);

  const lineRegister = () => {
    switch (props.information.prefix) {
      case PREFIX_UFAONLINE1:
        return (
          <>
            <div style={{ marginLeft: 16, flexDirection: "row" }}>
              <Link href="https://lin.ee/wEUUWQq">
                <a target="_blank">
                  <div className="btn" style={{ marginRight: -15 }}>
                    <div className="btn btn-green">สมัคร</div>
                  </div>
                </a>
              </Link>
              <Link href={`/login/${prefix}`}>
                <a>
                  <div className="btn" style={{ marginRight: 0 }}>
                    <div className="btn btn-yellow">ล็อกอิน</div>
                  </div>
                </a>
              </Link>
            </div>
          </>
        );

      default:
        return (
          <>
            <div style={{ marginLeft: 16, flexDirection: "row" }}>
              <Link href={`/register/${prefix}`}>
                <a>
                  <div className="btn" style={{ marginRight: -15 }}>
                    <div className="btn btn-green">สมัคร</div>
                  </div>
                </a>
              </Link>
              <Link href={`/login/${prefix}`}>
                <a>
                  <div className="btn" style={{ marginRight: 0 }}>
                    <div className="btn btn-yellow">ล็อกอิน</div>
                  </div>
                </a>
              </Link>
            </div>
          </>
        );
    }
  };

  return <>{lineRegister()}</>;
}

export default NavLoginView;
