import { AppReducerStateProps, store } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";
import get_all_game, {
  GameTypeID,
  GetAllGamesResponseResult,
} from "lib/worker/api/get_all_game";
import { MaxTag } from "components/view/tag";
import { Modal } from "react-bootstrap";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";
import start_game from "lib/worker/api/start_game";
import { MaxButton, StatusMaxButton } from "components/view/button";
import get_transaction_by_game_id_total_amount_get_win from "lib/worker/api/get_transaction_by_game_id_total_amount_get_win";
import insert_transaction_favorite_game from "lib/worker/api/insert_transaction_favorite_game";
import get_game_by_symbol from "lib/worker/api/get_game_by_symbol";
import Spinner from "components/view/loader";
import {
  DetailedHTMLProps,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { checkLogin } from "lib/check_login";
import { alertWithMessage } from "./message_modal";
import get_transaction_favroite_game from "lib/worker/api/get_transaction_favroite_game";
import SectionCasinoGameBox from "components/casino/section_casino_game_box";
import get_game_vol_hight from "lib/worker/api/get_game_vol_hight";

interface AllGameModalProps {
  state: AppReducerStateProps;
  closeModal: Function;
}

var bodyRef: any = null;

export const alertWithSymbolGameModal = (symbol: string) => {
  if (
    bodyRef != null &&
    bodyRef.current !== null &&
    bodyRef.current !== undefined
  ) {
    bodyRef.current.scrollTop = 0;
  }

  get_game_by_symbol({ symbol: symbol }).then((game) => {
    store.dispatch({
      type: memberActionTypes.ACTION_OPEN_MODAL_WITH_GAME_MODAL_OPEN,
      payload: game,
    });
  });
};

export const alertWithGameModal = (game: GetAllGamesResponseResult) => {
  if (
    bodyRef != null &&
    bodyRef.current !== null &&
    bodyRef.current !== undefined
  ) {
    bodyRef.current.scrollTop = 0;
  }

  store.dispatch({
    type: memberActionTypes.ACTION_OPEN_MODAL_WITH_GAME_MODAL_OPEN,
    payload: game,
  });
};

function AllGameModal(props: AllGameModalProps) {
  const {
    amountTotalWinByGame,
    isModalGame,
    modalGame,
    gameAllVender,
    gamesVolHight,
    gameAll,
  } = props.state.member;
  const { closeModal } = props;
  const { YLFishing, DragoonSoft } = gameAllVender;
  const [spinner, setSpinner] = useState<boolean>(false);
  const [isPlayLoading, setIsPlayLoading] = useState<boolean>(false);
  const [isPlayLoadingTest, setIsPlayLoadingTest] = useState<boolean>(false);
  const [spinnerLastPlayHighCredit, setSpinnerLastPlayHighCredit] =
    useState<boolean>(false);
  const modalBodyRef = useRef<any>();
  bodyRef = modalBodyRef;

  useEffect(() => {
    if (isModalGame) {
      get_all_game();
      get_game_vol_hight();
    }
  }, [isModalGame]);

  useEffect(() => {
    if (!checkLogin()) {
      return;
    }

    setSpinnerLastPlayHighCredit(true);
    get_transaction_by_game_id_total_amount_get_win({
      gameID: modalGame?.ID,
    })
      .catch(() => {
        setSpinnerLastPlayHighCredit(false);
      })
      .finally(() => {
        setSpinnerLastPlayHighCredit(false);
      });
  }, [modalGame?.ID]);

  const updateFavorite = () => {
    setSpinner(true);

    insert_transaction_favorite_game({ game_id: modalGame?.ID })
      .then(() => {
        return get_transaction_favroite_game();
      })
      .then(() => {
        return get_transaction_by_game_id_total_amount_get_win({
          gameID: modalGame?.ID,
        });
      })
      .finally(() => {
        setSpinner(false);
      })
      .catch((error) => {
        alertWithMessage(error.response.data.message);
      });
  };

  const displayTypeGame = (): DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > => {
    switch (modalGame?.GameTypeID) {
      case GameTypeID.liveCasino:
        return (
          <div className="font-white">
            ถ่ายทอดสด <span className="circle-tag"></span>
          </div>
        );
      case GameTypeID.table:
        return <div>เกมโต๊ะ</div>;
      case GameTypeID.sceratchGame:
        return <div>ขูดบัตร</div>;
      case GameTypeID.slot:
        return <div>Line: {modalGame?.Lines}</div>;
      default:
        return <div>Line: {modalGame?.Lines}</div>;
    }
  };

  const DisplayInfomationGame = () => {
    return (
      <div className="col-6">
        <div className="font-white margin-top-20">{displayTypeGame()}</div>
        <div>
          <span>{modalGame?.NameTH}</span>
        </div>
        {spinnerLastPlayHighCredit ? (
          <div className="font-white">
            <Spinner></Spinner>
          </div>
        ) : (
          <div className="font-white">
            เครดิตที่ท่านได้สูงสุดจากเกม:{" "}
            {amountTotalWinByGame.AfterBetAmount == 0 ? (
              <span>ไม่มีการเล่น</span>
            ) : (
              (amountTotalWinByGame.AfterBetAmount ?? 0).format(0, 0)
            )}
          </div>
        )}
      </div>
    );
  };

  const DisplayInfomationVolHight = () => {
    return (
      <>
        <div className="margin-top-10">รายการยอดนิยม</div>
        <div className="row wrapper">
          {(gamesVolHight ?? []).map((el, i) => {
            return (
              <div
                className="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 grid margin-top-10"
                key={`${i}sss--s`}
                onClick={() => {
                  alertWithGameModal(el);
                }}
              >
                <MaxTag game={el}></MaxTag>
                <Img
                  className="img-fluid"
                  ratio={0.6}
                  src={el.Img ?? ""}
                  alt={el.NameTH}
                ></Img>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const newFilterRelated = (): GetAllGamesResponseResult[] => {
    var ralateds: GetAllGamesResponseResult[] = [];
    var counting: number = 0;
    var isAddOn: boolean = false;

    for (let index = 0; index < gameAll.length; index++) {
      const element = gameAll[index];

      if (element.VenderName != modalGame?.VenderName) {
        continue;
      }

      if (element.Symbol == modalGame?.Symbol) {
        isAddOn = true;
        continue;
      }

      if (!isAddOn) {
        continue;
      }

      if (counting < 20) {
        counting++;
        ralateds = [...ralateds, element];
      }
    }

    return ralateds;
  };

  const DisplayRelatedContent = () => {
    return (
      <>
        <div className="margin-top-10">เนื้อหาที่เกี่ยวข้อง</div>
        {newFilterRelated().length == 0 && (
          <div key={"empppp"} className="row center-row-content">
            <div className="margin-top-10">ไม่พบเนื้อเพิ่มเติม</div>
          </div>
        )}
        <div className="row wrapper">
          {newFilterRelated().length != 0 &&
            newFilterRelated().map((el, i) => {
              return (
                <div
                  className="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 grid margin-top-10"
                  key={`${i}-related`}
                  onClick={() => {
                    alertWithGameModal(el);
                  }}
                >
                  <MaxTag game={el}></MaxTag>
                  <Img ratio={0.6} src={el.Img ?? ""}></Img>
                </div>
              );
            })}
        </div>
      </>
    );
  };

  return (
    <Modal
      {...props}
      size="lg"
      scrollable={true}
      contentClassName="view-background-transparent"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalGame}
      animation={false}
      onHide={() => {
        closeModal();
      }}
      dialogClassName="modal-dialog"
    >
      <Modal.Header
        closeButton
        className="modal-header-box-game"
      ></Modal.Header>
      <Modal.Body ref={modalBodyRef}>
        <div className="row">
          <div className="col-5 col-md-2">
            <MaxTag game={modalGame}></MaxTag>
            <Img
              ratio={0.6}
              className="full-width-image border-radius"
              src={modalGame?.Img ?? ""}
              alt={modalGame?.NameTH}
            ></Img>
          </div>
          {DisplayInfomationGame()}
        </div>
        <div className="row wrapper">
          <div className="col-6 grid">
            <MaxButton
              title="เล่น"
              className="margin-top-10"
              loading={isPlayLoading}
              status={StatusMaxButton.submit}
              onClick={() => {
                setIsPlayLoading(true);
                start_game({
                  VenderPrepix: modalGame?.VenderPrepix,
                  Symbol: modalGame?.SymbolOriginal,
                })?.finally(() => {
                  setIsPlayLoading(false);
                });
              }}
            ></MaxButton>
          </div>
          <div className="col-6 grid">
            <MaxButton
              title="ทดลอง"
              className="margin-top-10"
              lock={!(modalGame?.Demo ?? false)}
              status={StatusMaxButton.tryplay}
              loading={isPlayLoadingTest}
              onClick={() => {
                setIsPlayLoadingTest(true);
                start_game({
                  VenderPrepix: modalGame?.VenderPrepix,
                  Symbol: modalGame?.SymbolOriginal,
                  IsDemo: true,
                })?.finally(() => {
                  setIsPlayLoadingTest(false);
                });
              }}
            ></MaxButton>
          </div>
        </div>
        <div className="row margin-top-10">
          <div
            className="col-6 text-center"
            onClick={() => {
              updateFavorite();
            }}
          >
            {spinner && (
              <div className="margin-top-10">
                <Spinner></Spinner>
              </div>
            )}
            {!spinner && (
              <div className="icon-fav-banner center-row-content">
                <Img
                  key={"iioo_skfm"}
                  ratio={1 / 1}
                  className="full-width-image margin-top-10"
                  src={
                    amountTotalWinByGame.IsFavorite
                      ? "icon-check-1.png"
                      : "icon-plus-1.png"
                  }
                ></Img>
              </div>
            )}
            <div>
              <small>รายการของฉัน</small>
            </div>
          </div>
        </div>
        {modalGame?.Content != null && (
          <>
            <div className="margin-top-10">ลายละเอียด</div>
            <div className="margin-top-10 font-white">{modalGame?.Content}</div>
          </>
        )}
        {DisplayRelatedContent()}
        {DisplayInfomationVolHight()}
        <SectionCasinoGameBox
          imageType="normalv2"
          venderName={"YL Fishing ยิงปลา"}
          results={YLFishing?.filter((value) => {
            return value.GameTypeID == GameTypeID.fishing;
          })}
        ></SectionCasinoGameBox>
        <SectionCasinoGameBox
          imageType="normalv2"
          venderName={"Dragoon Soft ยิงปลา"}
          results={DragoonSoft?.filter((value) => {
            return value.GameTypeID == GameTypeID.fishing;
          })}
        ></SectionCasinoGameBox>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className="col-12 grid">
          <MaxButton
            title="ปิด"
            status={StatusMaxButton.submit}
            onClick={() => {
              closeModal();
            }}
          ></MaxButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModal: () => {
      dispatch({
        type: memberActionTypes.ACTION_OPEN_MODAL_WITH_GAME_MODAL_MESSAGE_CLOSE,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllGameModal);
