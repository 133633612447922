import client from "../client";

export interface CreateDepositAmountRequest {
  amount: number;
  bank_member_Id: number;
}

export interface CreateDepositAmountResponse {
  amount?: number;
  transaction_id?: string;
}

export default function API(
  request: CreateDepositAmountRequest
): Promise<CreateDepositAmountResponse> {
  return client
    .post(`payment/create_deposit_amount`, request)
    .then((response) => response.data);
}
