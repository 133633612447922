import Img from "react-cloudimage-responsive";
import { GetAllGamesResponseResult } from "lib/worker/api/get_all_game";
import { alertWithGameModal } from "components/modal/all_game_modal";
import { connect } from "react-redux";
import { AppReducerStateProps } from "lib/store";
import { MaxTag } from "components/view/tag";

interface SectionCasinoGameBoxProps {
  state: AppReducerStateProps;
  venderName?: string;
  isFlagPromo?: boolean | undefined;
  titleBadge?: string | undefined;
  slotxo?: string | undefined;
  results?: GetAllGamesResponseResult[];
  imageType:
    | "normal"
    | "square"
    | "rec"
    | "squarev2"
    | "auto"
    | "normalv2"
    | undefined;
}

function SectionCasinoGameBox(props: SectionCasinoGameBoxProps) {
  const { venderName, results, imageType, isFlagPromo, titleBadge, slotxo } =
    props;
  const { saveTempModalGame } = props.state.member;

  const displayImage = (
    el: GetAllGamesResponseResult
  ): JSX.Element | undefined => {
    switch (imageType) {
      case "normal":
        return (
          <Img
            className="border-radius shadow"
            src={el.Img ?? ""}
            ratio={1 / 1}
            alt={el.NameEN}
          ></Img>
        );
      case "normalv2":
        return (
          <Img
            className="border-radius shadow shine"
            src={el.Img ?? ""}
            ratio={0.6 / 1}
            alt={el.NameEN}
          ></Img>
        );
      case "rec":
        return (
          <Img
            className="border-radius shadow"
            src={el.ImgRec ?? "el.Img"}
            ratio={1 / 1}
            alt={el.NameEN}
          ></Img>
        );
      case "square":
        return (
          <Img
            className="border-radius shadow"
            src={el.ImgSquare ?? ""}
            ratio={1 / 1}
            alt={el.NameEN}
          ></Img>
        );
      default:
        return (
          <Img
            className="border-radius shadow"
            src={el.Img ?? ""}
            ratio={1 / 1}
            alt={el.NameEN}
          ></Img>
        );
    }
  };

  return (
    <div key={venderName}>
      <div className="heading">
        <div className="font-bold">{venderName}</div>
        {slotxo == "none-line" ? (
          <></>
        ) : (
          <>
            {" "}
            <p className="bottom-line-yellow"></p>
          </>
        )}
      </div>
      <div className="row wrapper">
        {(results ?? []).map((el, i) => {
          return (
            <div
              className="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 grid margin-top-20"
              key={`game_casino${i}`}
            >
              <div
                className={`${
                  saveTempModalGame?.ID == el.ID ? "view-glowing" : ""
                }`}
                onClick={() => {
                  alertWithGameModal(el);
                }}
              >
                {isFlagPromo && (
                  <div
                    style={{ marginTop: "5px" }}
                    className={`tag-game flag-event`}
                  >
                    {titleBadge}
                  </div>
                )}
                <MaxTag game={el}></MaxTag>
                {displayImage(el)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(SectionCasinoGameBox);
