import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { AppReducerStateProps, store } from "../../lib/store";
import { alertWithMessage } from "./message_modal";
import apiGetinfo, { BankByMember } from "../../lib/worker/api/getinfo";
import { alertWithSuccessMessage } from "./success_modal";
import { useEffect } from "react";
import notification_withdraw_to_vender from "../../lib/worker/api/notification_withdraw_to_vender";
import { memberActionTypes } from "lib/store/member/reducer";
import Spinner from "components/view/loader";
import { TextFieldFromSendButton } from "components/view/text_field_from";
import { MaxButton, StatusMaxButton } from "components/view/button";
import BankAccountShow from "components/view/bank_account_show";
import { useRouter } from "next/router";
import {
  IsAutoSelectBankOneAccount,
  IsCheckMutiBankInModal,
} from "lib/helper/is_check_muti_bank";

interface AlertWithWithDrawProps {
  state: AppReducerStateProps;
  closeModal: Function;
}

export const alertWithWithDraw = () => {
  store.dispatch({
    type: memberActionTypes.ACTION_WITHDRAW_IS_OPEN_MODAL,
  });
};

export const alertWithWithDrawClose = () => {
  store.dispatch({
    type: memberActionTypes.ACTION_WITHDRAW_IS_CLOSE_MODAL,
  });
};

function AlertWithWithDraw(props: AlertWithWithDrawProps) {
  const { isLogin, isGetInfoLoading } = props.state.member;
  const { BankName, Balance } = props.state.member.getinfo;
  const { isWithdrawModal } = props.state.member;
  const { closeModal } = props;
  const [inputMoney, setInputMoney] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [chooseBankMember, setChooseBankMember] = useState<BankByMember>();
  const getinfo = props.state.member.getinfo;
  const router = useRouter();

  useEffect(() => {
    if (isWithdrawModal) {
      apiGetinfo();
      setChooseBankMember(IsAutoSelectBankOneAccount(getinfo));
    }
  }, [isWithdrawModal]);

  const close = () => {
    setInputMoney("");
    setChooseBankMember(undefined);
    closeModal();
  };

  const goToAddBank = () => {
    close();
    if (!isLogin) {
      alertWithMessage("กรุณาเข้าสู่ระบบ");
      return;
    }
    router.push("/add_bank");
  };

  const withdrawMoney = () => {
    if (!isLogin) {
      alertWithMessage("ท่านยังไม่ได้เข้าสู่ระบบ");
      return;
    }

    if (BankName == null) {
      alertWithMessage("ท่านยังไม่ได้ระบุบัญชีธนาคาร");
      return;
    }

    setLoading(true);
    notification_withdraw_to_vender({
      amount: parseFloat(inputMoney ?? "0"),
      bank_member_Id: chooseBankMember?.ID ?? 0,
    })
      .then(() => {
        apiGetinfo();
        alertWithSuccessMessage("ถอนเงินสำเร็จกรุณารอการตอบรับจากระบบ");
        close();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        alertWithMessage(error.response.data.message);
      });
  };

  const ChooseBank = () => {
    return (
      <>
        <div className="row">
          {getinfo.Banks?.map((value, i) => {
            return (
              <div key={i} className="col-12">
                <div
                  onClick={() => {
                    setChooseBankMember(value);
                  }}
                >
                  <BankAccountShow
                    bankAccount={value?.Type}
                    bankImage={value?.Image}
                    bankName={value?.BankName}
                    bankColor={value?.Color}
                    bankNumber={value?.BankNumber}
                  ></BankAccountShow>
                </div>
              </div>
            );
          })}
          {IsCheckMutiBankInModal(getinfo) && (
            <div className="col-12">
              <div
                className="card-add-bank color-font-card margin-top-15 text-center"
                style={{ paddingTop: 63, paddingBottom: 63 }}
                onClick={goToAddBank}
              >
                <span>+ เพิ่มบัญชีธนาคาร</span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const WithdrawInput = () => {
    return (
      <>
        <TextFieldFromSendButton
          key={"asiglakn33wehsdfg"}
          title="ระบุยอดเงิน"
          titleButton="ทั้งหมด"
          value={inputMoney}
          type="number"
          onChange={(e) => {
            setInputMoney(e.target.value);
          }}
          onClick={() => {
            setInputMoney(`${Balance ?? 0}`);
          }}
        ></TextFieldFromSendButton>
        <div className="bottom-line-black" style={{ paddingTop: 10 }}></div>
        <div style={{ marginTop: 10 }}>
          <label style={{ marginTop: 0 }}>ยอดเงินคงเหลือ </label>
          <label>
            {isGetInfoLoading ? (
              <Spinner></Spinner>
            ) : (
              <>
                <label
                  style={{
                    padding: "5px 5px 5px 5px",
                    borderRadius: 20,
                    fontWeight: "bold",
                  }}
                >
                  <span>{Balance?.format(2, 0)} THB</span>
                </label>{" "}
              </>
            )}
          </label>
        </div>
        <div
          className="row center-row-content col-12"
          style={{ marginBottom: 20, marginTop: 20 }}
        >
          <div className="col-6" style={{ paddingLeft: 0 }}>
            <MaxButton
              title="ปิด"
              status={StatusMaxButton.close}
              onClick={() => {
                close();
              }}
            ></MaxButton>
          </div>
          <div className="col-6" style={{ paddingRight: 0 }}>
            <MaxButton
              title="ยืนยัน"
              status={StatusMaxButton.submit}
              loading={loading}
              onClick={withdrawMoney}
            ></MaxButton>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      {...props}
      size="lg"
      scrollable={true}
      contentClassName="view-background-deposit-withdraw"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isWithdrawModal}
      onHide={() => {
        close();
      }}
      dialogClassName="modal-dialog"
    >
      <Modal.Header
        closeButton
        className="modal-header-box-game btn-close-white"
      ></Modal.Header>
      <Modal.Body>
        <h3
          className="w-100 text-center"
          style={{ fontWeight: "bold", marginTop: -15 }}
        >
          <span>ถอนเงิน</span>
        </h3>
        {!chooseBankMember && ChooseBank()}
        {chooseBankMember && WithdrawInput()}
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModal: () => {
      dispatch({
        type: memberActionTypes.ACTION_WITHDRAW_IS_CLOSE_MODAL,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertWithWithDraw);
