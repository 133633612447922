import client from "../client";

export interface GetRoomRequest {
  prefix?: string;
  username?: string;
}

export interface GetRoomResponse {
  ID?: boolean;
  Username?: string;
  CreateAt?: number;
  AdminCountUnRead?: number;
  UserCountUnRead?: number;
  LastMessage?: boolean;
}

export default function API(request: GetRoomRequest): Promise<GetRoomResponse> {
  return client
    .get(`chat/get_room`, {
      params: {
        prefix: request.prefix,
        username: request.username,
      },
    })
    .then((response) => response.data);
}
