import client from "../client";
import { store } from "../../store";
import { memberActionTypes } from "lib/store/member/reducer";
import { GetAllGamesResponseResult } from "./get_all_game";

export default function API(): Promise<GetAllGamesResponseResult[]> {
  return client
    .get(`members/get_transaction_favroite_game`)
    .then((response) => response.data)
    .then((json: GetAllGamesResponseResult[]) => {
      store.dispatch({
        type: memberActionTypes.ACTION_USER_UPDATE_GAME_FAVORITE,
        payload: json,
      });
      return json;
    });
}
