import { useState } from "react";
import { connect } from "react-redux";
import NavDropDown from "./nav_dropdown";

function NavBlance() {
  const [showDropDown, setShowDropDown] = useState(false);

  return (
    <div style={{ flexDirection: "row" }}>
      <div>
        <NavDropDown
          showDropDown={showDropDown}
          setShowDropDown={setShowDropDown}
        ></NavDropDown>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    getinfo: state.member.getinfo,
  };
};

export default connect(mapStateToProps)(NavBlance);
