import { GetInfoResponse } from "lib/worker/api/getinfo";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";
import NavDropdown from "react-bootstrap/NavDropdown";
import Router from "next/router";
import CheckOutModal, {
  alertCheckOutModal,
} from "components/modal/check_out_modal";
import { AppReducerStateProps } from "lib/store";
import { alertWithDeposit } from "components/modal/deposit_modal";
import { alertWithWithDraw } from "components/modal/withdraw_modal";

interface NavDropDownProps {
  state: AppReducerStateProps;
  getinfo: GetInfoResponse;
  showDropDown: boolean;
  setShowDropDown: Function;
}

function NavDropDown(props: NavDropDownProps) {
  const getinfo: GetInfoResponse = props.getinfo;
  const { showDropDown, setShowDropDown } = props;

  return (
    <NavDropdown
      id="dropdown-basic"
      title={<span>{getinfo.Username ?? "..."}</span>}
      show={showDropDown}
      style={{ marginRight: 0, inset: "2px 0px 0px 0px" }}
      onClick={() => {
        setShowDropDown(!showDropDown);
      }}
    >
      <div className="center-content">
        <div>
          <button
            className="view-background-gradinet-nav view-background-border-non-radius"
            style={{ padding: "10px 10px 10px 10px", width: 168 }}
            disabled
          >
            <div className="padding-bottom-10">
              <span
                className="font-l text-white"
                style={{ fontWeight: "bold" }}
              >
                {getinfo.Balance?.format(2, 0)} บาท
              </span>
              <div className="row">
                <div className="col-12">
                  <div style={{ display: "inline-flex", margin: "0 auto" }}>
                    <span className="">{getinfo.SMPoint?.format(2, 0)}</span>
                    <div className="icon-head">
                      <Img
                        ratio={1}
                        className="margin-left-5 margin-bottom-4"
                        src="ufabet-diamond-icon.png"
                      ></Img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className="center-content" style={{ marginTop: 1 }}>
        <div className="">
          <div>
            <div className="btn-group">
              <div
                onClick={() => {
                  alertWithDeposit();
                }}
              >
                <button
                  className="view-background-gradinet-green text-white view-background-border-non-radius-green"
                  style={{ width: 83, height: 50 }}
                >
                  ฝาก
                </button>
              </div>
              <div
                onClick={() => {
                  alertWithWithDraw();
                }}
              >
                <button
                  className="view-background-gradinet-red view-background-border-non-radius-red text-white"
                  style={{ width: 85, height: 50 }}
                >
                  ถอน
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavDropdown.Item
        id="nav-dropdown"
        onClick={() => {
          Router.push("/invite_agent");
        }}
      >
        <div className="row">
          <div style={{ marginLeft: 5, fontSize: 16, marginTop: 5 }}>
            <div
              className="icon-head"
              style={{ float: "left", marginRight: 5, marginTop: 5 }}
            >
              <Img
                ratio={1 / 1}
                src="ufabet-invite-icon.png"
                alt="ตัวแทน"
              ></Img>
            </div>
            ตัวแทน
          </div>
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        id="nav-dropdown"
        onClick={() => {
          Router.push("/transaction_udiamond");
        }}
      >
        <div className="row">
          <div style={{ marginLeft: 5, fontSize: 16, marginTop: 5 }}>
            <div
              className="icon-head"
              style={{ float: "left", marginRight: 5, marginTop: 5 }}
            >
              <Img
                ratio={1 / 1}
                src="ufabet-letter-icon.png"
                alt="ประวัติเพชร"
              ></Img>
            </div>
            ประวัติเพชร
          </div>
        </div>
      </NavDropdown.Item>
      {/* <NavDropdown.Item
        id="nav-dropdown"
        onClick={() => {
          Router.push("/uGame/uspin");
        }}
      >
        <div className="row font-base">
          <div style={{ marginLeft: 5, fontSize: 12, marginTop: 5 }}>
            <div
              className="icon-head"
              style={{ float: "left", marginRight: 5 }}
            >
              <Img
                ratio={1 / 1}
                src="slot-icon-blue.png"
                alt="กงล้อลุ้นโชค ลุ้นรับ iphone 13 pro"
              ></Img>
            </div>
            กงล้อลุ้นโชค
          </div>
        </div>
      </NavDropdown.Item> */}
      <NavDropdown.Item
        id="nav-dropdown"
        onClick={() => {
          Router.push("/exchange");
        }}
      >
        <div className="row">
          <div style={{ marginLeft: 5, fontSize: 16, marginTop: 5 }}>
            <div
              className="icon-head"
              style={{ float: "left", marginRight: 5, marginTop: 5 }}
            >
              <Img
                ratio={1 / 1}
                src="ufabet-savecash.png"
                alt="แลกเครดิต"
              ></Img>
            </div>
            แลกเครดิต
          </div>
        </div>
      </NavDropdown.Item>
      <NavDropdown.Item
        id="nav-dropdown"
        onClick={() => {
          Router.push("/new_password");
        }}
      >
        <div className="row">
          <div style={{ marginLeft: 5, fontSize: 16, marginTop: 5 }}>
            <div
              className="icon-head"
              style={{ float: "left", marginRight: 5, marginTop: 5 }}
            >
              <Img
                ratio={1 / 1}
                src="changepassword-2.png"
                alt="เปลี่ยนรหัสผ่าน"
              ></Img>
            </div>
            เปลี่ยนรหัสผ่าน
          </div>
        </div>
      </NavDropdown.Item>

      <NavDropdown.Item id="nav-dropdown">
        <div className="row">
          <div
            style={{ marginLeft: 5, fontSize: 16, marginTop: 5 }}
            onClick={() => {
              alertCheckOutModal();
            }}
          >
            <div
              className="icon-head"
              style={{ float: "left", marginRight: 5, marginTop: 5 }}
            >
              <Img ratio={1 / 1} src="ufabet-logout.png" alt="ออกจากระบบ"></Img>
            </div>
            ออกจากระบบ
          </div>
          <CheckOutModal></CheckOutModal>
        </div>
      </NavDropdown.Item>
    </NavDropdown>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    getinfo: state.member.getinfo,
    state: state,
  };
};

export default connect(mapStateToProps)(NavDropDown);
