import client from "../client";

export interface GetPromotionRemaingingResponse {
  ID?: number;
  Disable?: boolean;
  Amount?: number;
  PromitionTypeId?: number;
  FinalTurnover?: number;
  CreatedAt?: Date;
  UpdatedAt?: Date;
  DisplayCreatedAt?: string;
  DisplayUpdatedAt?: string;
  ProgressBar?: number;
  FullFinalAmouunt?: number;
  Multiplier?: number;
  RemainingAmount?: number;
}

export default function API(): Promise<GetPromotionRemaingingResponse[]> {
  return client
    .get(`members/get_promotion_remaining`, {})
    .then((response) => response.data)
    .then((json) => {
      return json;
    });
}
