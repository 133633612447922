import Head from "next/head";
import React, { useEffect, useState } from "react";
import getinfo from "../../lib/worker/api/getinfo";
import NavNavigation from "../view/nav_navigation";
import PushDepositEvent from "components/PusherEvent";
import RejectModal from "components/modal/reject_modal";
import { useRouter } from "next/router";
import Link from "next/link";
import NotificationMessage from "components/view/notification_message";
import { checkLogin } from "lib/check_login";
import get_vender_domain_infomation from "lib/worker/api/get_vender_domain_infomation";
import NavBar from "components/view/nav_bar";
import OptimizeImage from "components/view/optimize_image";
import MessageModal from "components/modal/message_modal";
import SuccessModal from "components/modal/success_modal";
import { THEME_COLOR } from "lib/config/global";
import { AppReducerStateProps } from "lib/store";
import { connect } from "react-redux";
import theme_color from "lib/worker/api/theme_color";
import {
  CheckWithPrefix,
  CheckWithPrefixResponse,
} from "lib/infomation_vender/document";
import AlertWithDeposit from "components/modal/deposit_modal";
import AlertWithWithDraw from "components/modal/withdraw_modal";
import MenuModal from "components/modal/menu_modal";
import { GetServerSideProps } from "next";
import get_all_content_by_prefix from "lib/worker/api/get_all_content_by_prefix";
import header_config from "lib/worker/api/header_config";
import global from "lib/config/global";
import AllGameModal from "components/modal/all_game_modal";
import NotiVenderChat from "components/view/noti_venderchat";
import get_popup_list_list_on_website, {
  GetPopupSettingByVenderIDSQL,
} from "lib/worker/api/get_popup_list_list_on_website";
import PopUpModal from "components/modal/pop_up_modal";
import { useCookies } from "react-cookie";

export interface LayoutMasterProps {
  content_setting: ContentSetting;
  state?: AppReducerStateProps;
  information: CheckWithPrefixResponse;
}

export interface ContentSetting {
  title: string;
  description?: string | undefined;
}

const LayoutMaster: React.FC<LayoutMasterProps> = ({ children, ...rest }) => {
  const { title, description } = rest.content_setting;
  const { information } = rest;
  const router = useRouter();
  const { prefix } = global().gobalVariable;
  const { themeColor } = rest.state!.member;
  const [lineid, setlineid] = useState<string | null | undefined>(null);
  const [popups, setpopups] = useState<GetPopupSettingByVenderIDSQL[]>([]);
  const [showModalPopup, setShowModalPopup] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies(["popup_list_list_on_website"]);

  useEffect(() => {
    get_popup_list_list_on_website({
      prefix: rest.information.prefix,
    })
      .then((json) => {
        setpopups(json);
        return json;
      })
      .then((value) => {
        if (value.length > 0) {
          if (!cookies.popup_list_list_on_website) {
            setShowModalPopup(true);
          }
        }
      });
  }, []);

  useEffect(() => {
    if (checkLogin()) {
      getinfo();
    }

    async function loadinfo() {
      try {
        await theme_color({
          prefix: prefix ?? "snb",
          themeColor: localStorage.getItem(THEME_COLOR),
        });
        const infomation = await get_vender_domain_infomation({
          domain: information.domain,
        });
        await theme_color({
          prefix: infomation.Prefix,
          themeColor: localStorage.getItem(THEME_COLOR),
        });

        const infomations = CheckWithPrefix({ host: information.domain });
        setlineid(infomations.lineidchat);
      } catch (error) {}
    }

    loadinfo();
  }, []);

  const closePopup = () => {
    let housrexpires = new Date();
    housrexpires.setTime(housrexpires.getTime() + 60 * 60 * 1000);
    setCookie("popup_list_list_on_website", true, { expires: housrexpires });
    setShowModalPopup(!showModalPopup);
  };

  const candisplay = (): any => {
    if (router.pathname.includes("contact")) {
      return children;
    } else {
      return information.disable_web_site ? (
        <div
          style={{
            minHeight: "80vh",
            padding: "4rem 0",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>ทางเราขอปิดบริการชั่วคราว</h1>
          <h4>ขออภัยเป็นอย่างยิ่ง แล้วเราจะกลับมาพบกันใหม่</h4>
          <p>
            สามารถต่อเราได้ผ่านทางนี้{" "}
            <Link href={`/contact/${information.prefix}`}>
              <a>ติดต่อ</a>
            </Link>
          </p>
        </div>
      ) : (
        children
      );
    }
  };

  return (
    <div className="main-content">
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={`${title}`}></meta>
        <meta name="title" content={`${title}`}></meta>
        <meta
          name="description"
          property="description"
          content={description ?? ""}
        ></meta>
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no"
        ></meta>
        <style>{themeColor}</style>
      </Head>
      {router.pathname.includes("contact") == false && (
        <div>
          {information.isChatWebsite && (
            <>
              <NotiVenderChat></NotiVenderChat>
              <Link href={`/contact/${information.prefix}`}>
                <a className="floating-btn-2">
                  <OptimizeImage
                    className="margin-right-20"
                    src="icon-chat-4.png"
                    alt="แชท 24 ชั่วโมง"
                  ></OptimizeImage>
                </a>
              </Link>
            </>
          )}
          {lineid != null ? (
            <Link href={lineid}>
              <a className="floating-btn">
                <OptimizeImage
                  className="margin-right-20"
                  src="live-chat-icon2.png"
                  alt="LINE SUPPORT"
                ></OptimizeImage>
              </a>
            </Link>
          ) : (
            <>
              <NotificationMessage></NotificationMessage>
              <Link href={`/contact/${prefix}`}>
                <a className="floating-btn">
                  <OptimizeImage
                    className="margin-right-20"
                    src="icon-chat-4.png"
                    alt="แชท 24 ชั่วโมง"
                  ></OptimizeImage>
                </a>
              </Link>
            </>
          )}
        </div>
      )}
      <NavBar infomation={information}></NavBar>
      <PushDepositEvent></PushDepositEvent>
      <RejectModal></RejectModal>
      <AlertWithDeposit></AlertWithDeposit>
      <AlertWithWithDraw></AlertWithWithDraw>
      <MessageModal information={information}></MessageModal>
      <AllGameModal></AllGameModal>
      <SuccessModal></SuccessModal>
      <MenuModal></MenuModal>
      <NavNavigation infomation={information}></NavNavigation>
      {popups.map((el, i) => {
        if (el.Disable === true) {
          return <></>;
        }
        return (
          <>
            <PopUpModal
              key={i}
              popups={popups}
              infomation={information}
              show={showModalPopup}
              handleClose={closePopup}
            ></PopUpModal>
          </>
        );
      })}
      <main className="children-top">{candisplay()}</main>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<any> = async (context) => {
  const { req } = context;
  const infomation = CheckWithPrefix({ host: req.headers.host as string });
  try {
    const [content] = await Promise.all([
      get_all_content_by_prefix({
        prefix: infomation.prefix,
      }),

      header_config({
        prefix: infomation.prefix,
      }),
    ]);

    return {
      props: {
        infomation: infomation,
        content: content,
      },
    };
  } catch (error) {
    return {
      props: {
        infomation: infomation,
      },
    };
  }
};

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(
  LayoutMaster
) as React.FC<LayoutMasterProps>;
