import { BankByMember, GetInfoResponse } from "lib/worker/api/getinfo";

export function IsCheckMutiBank(getinfo: GetInfoResponse): boolean {
  const bankCount = getinfo.Banks?.length ?? 0;

  if (getinfo.IsMutiBank) {
    return true;
  } else {
    if (bankCount == 0) {
      return true;
    } else {
      return false;
    }
  }
}

export function IsCheckMutiBankInModal(getinfo: GetInfoResponse): boolean {
  const bankCount = getinfo.Banks?.length ?? 0;
  if (bankCount == 0) {
    return true;
  } else {
    return false;
  }
}

export function IsAutoSelectBankOneAccount(
  info: GetInfoResponse
): BankByMember | undefined {
  const banks = info.Banks ?? [];

  if (banks.length == 1) {
    for (let index = 0; index < banks.length; index++) {
      const element = banks[index];
      return element;
    }
  }

  return undefined;
}
