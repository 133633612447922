import { MaxButton, StatusMaxButton } from "components/view/button";
import Spinner from "components/view/loader";
import { LeftHaveNoPrefix } from "components/view/text_field_from";
import { memberActionTypes } from "lib/store/member/reducer";
import React, { useEffect, useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import Img from "react-cloudimage-responsive";
import { useCookies } from "react-cookie";
import { connect } from "react-redux";
import { checkLogin } from "../../lib/check_login";
import { AppReducerStateProps, store } from "../../lib/store";
import create_deposit_amount from "../../lib/worker/api/create_deposit_amount";
import get_deposit_amount from "../../lib/worker/api/get_deposit_amount";
import get_transaction_get_member_list_of_deposit_money, {
  GetTransactionGetMemberListOfDepositMoneyRepsonse,
} from "../../lib/worker/api/get_transaction_get_member_list_of_deposit_money";
import get_vender_bank_account_from_member_by_id from "../../lib/worker/api/get_vender_bank_account_from_member_by_id";
import notification_deposit_to_vender from "../../lib/worker/api/notification_deposit_to_vender";
import { alertWithMessage } from "./message_modal";
import Timer from "../view/timer";
import TranferIcon from "components/view/tranfer_icon";
import cancel_deposit_with_member from "lib/worker/api/cancel_deposit_with_member";
import { GetPromotionTypesResponse } from "lib/worker/api/get_promotion_types";
import { getCookie, removeCookies } from "cookies-next";
import BankAccountShow from "components/view/bank_account_show";
import { BankByMember, GetInfoResponse } from "lib/worker/api/getinfo";
import {
  IsAutoSelectBankOneAccount,
  IsCheckMutiBankInModal,
} from "lib/helper/is_check_muti_bank";
import { useRouter } from "next/router";

interface AlertWithDepositProps {
  state: AppReducerStateProps;
  getinfo: GetInfoResponse;
  closeModal: Function;
}

export const alertWithDeposit = () => {
  store.dispatch({
    type: memberActionTypes.ACTION_DEPOSIT_IS_OPEN_MODAL,
  });
};

export const alertWithDepositClose = () => {
  store.dispatch({
    type: memberActionTypes.ACTION_DEPOSIT_IS_CLOSE_MODAL,
  });
};

function AlertWithDeposit(props: AlertWithDepositProps) {
  const [cookies, setCookie, removeCookie] = useCookies([
    "transactions_deposit_id",
  ]);
  const { isLogin } = props.state.member;
  const { transactionDeposit, bankVender } = props.state.member;
  const { BankName, BankNumber, BankAccount, BankImage, BankColor } =
    props.state.member.getinfo;
  const { isDepositModal } = props.state.member;
  const { closeModal } = props;
  const [inputMoney, setInputMoney] = useState<string>();
  const moneyInputs: number[] = [10, 50, 100, 200, 500, 1000, 5000, 10000];
  const [loading, setLoading] = useState<boolean>(false);
  const [prepareMoneyLoading, setPrepareMoneyLoading] =
    useState<boolean>(false);
  const isPrepareTransactionID = cookies.transactions_deposit_id;
  const [amountGen, setAmountGen] = useState<number>(0);
  const [promotion, setpromotion] = useState<GetPromotionTypesResponse>();
  const getinfo = props.getinfo;
  const [chooseBankMember, setChooseBankMember] = useState<BankByMember>();
  const router = useRouter();

  useEffect(() => {
    const getPromotion =
      getCookie("promotion_hash_key_editing")?.toString() ?? "";
    if (getPromotion != "") {
      const passPromotion = JSON.parse(getPromotion);

      setpromotion(passPromotion);
    } else {
      setpromotion(undefined);
    }

    if (checkLogin()) {
      reloadPrepareMoneyFromTransactionIDOld();
    }

    if (isDepositModal) {
      setChooseBankMember(IsAutoSelectBankOneAccount(getinfo));
    }
  }, [isDepositModal]);

  useEffect(() => {
    if (checkLogin()) {
      get_vender_bank_account_from_member_by_id();
      get_transaction_get_member_list_of_deposit_money();
    }
  }, [isLogin]);

  const reloadPrepareMoneyFromTransactionIDOld = () => {
    if (cookies.transactions_deposit_id) {
      get_deposit_amount({
        transaction_id: `${cookies.transactions_deposit_id ?? ""}`,
      })
        .then((json) => {
          setAmountGen(json.Amount ?? 0);
        })
        .catch(() => {
          if (isDepositModal) {
            removeCookies("transactions_deposit_id");
          }
        });
      return;
    }
    get_deposit_amount({ transaction_id: `${cookies.transactions_deposit_id}` })
      .then((json) => {
        setAmountGen(json.Amount ?? 0);
        setCookie(
          "transactions_deposit_id",
          `${cookies.transactions_deposit_id ?? ""}`
        );
      })
      .catch(() => {
        if (isDepositModal) {
          removeCookies("transactions_deposit_id");
        }
      });
  };

  const goToAddBank = () => {
    removeCookie("transactions_deposit_id");
    removeCookies("promotion_hash_key_editing");
    setChooseBankMember(undefined);
    closeModal();
    if (!isLogin) {
      alertWithMessage("กรุณาเข้าสู่ระบบ");
      return;
    }
    router.push("/add_bank");
  };

  const onPrepareSubmitMoney = () => {
    if (!isLogin) {
      alertWithMessage("ท่านยังไม่ได้เข้าสู่ระบบ");
      return;
    }

    if (BankName == null) {
      alertWithMessage("ท่านยังไม่ได้ระบุบัญชีธนาคาร");
      return;
    }

    if (promotion != undefined) {
      if ((promotion.Higher ?? 0) < parseFloat(inputMoney ?? "0")) {
        alertWithMessage(
          "ท่านฝากเครดิตเกินจำนวนสูงสุดของเงื่อนไขโปรโมชั่น กรุณาแก้ไขการแจ้งฝากเครดิตใหม่!"
        );

        return;
      }

      if ((promotion.Lower ?? 0) > parseFloat(inputMoney ?? "0")) {
        alertWithMessage(
          "ท่านฝากเครดิตต่ำกว่าเงื่อนไขโปรโมชั่น กรุณาแก้ไขการแจ้งฝากเครดิตใหม่!"
        );
        return;
      }
    }

    if (chooseBankMember?.ID == null) {
      alertWithMessage("ท่านยังไม่ได้เลือกบัญชีธนาคารของท่านในการแจ้งฝาก");
      return;
    }

    setPrepareMoneyLoading(true);
    setLoading(true);
    create_deposit_amount({
      amount: parseFloat(inputMoney ?? "0"),
      bank_member_Id: chooseBankMember?.ID,
    })
      .then((json) => {
        setCookie("transactions_deposit_id", json.transaction_id);
        return json;
      })
      .then((json) => {
        onSubmitMoney(json.transaction_id ?? "");
        return get_deposit_amount({ transaction_id: json.transaction_id });
      })
      .then((json) => {
        return json;
      })
      .then((json) => {
        setAmountGen(json.Amount ?? 0);
      })
      .catch((error) => {
        alertWithMessage(error.response.data.message);
      })
      .finally(() => {
        setPrepareMoneyLoading(false);
        setLoading(false);
      });
  };

  const onSubmitMoney = (transactions_deposit_id: string) => {
    if (chooseBankMember?.ID == null) {
      alertWithMessage("ท่านยังไม่ได้เลือกบัญชีธนาคารของท่านในการแจ้งฝาก");
      return;
    }

    setLoading(true);
    notification_deposit_to_vender({
      bank_member_Id: chooseBankMember?.ID,
      transaction_id: transactions_deposit_id,
      promotion_type_id: promotion?.ID,
    })
      .then(() => {
        return get_transaction_get_member_list_of_deposit_money();
      })
      .then(() => {
        removeCookies("transactions_deposit_id");
        removeCookie("transactions_deposit_id");
        removeCookies("promotion_hash_key_editing");
        setInputMoney("");
      })
      .finally(() => {
        setLoading(false);
        reloadPrepareMoneyFromTransactionIDOld();
      })
      .catch((error) => {
        setLoading(false);
        alertWithMessage(error.response.data.message);
      });
  };

  const rejectWithMember = () => {
    for (let index = 0; index < transactionDeposit.length; index++) {
      const element = transactionDeposit[index];
      if (element.HashKey) {
        cancel_deposit_with_member({ hash_key: element.HashKey }).finally(
          () => {
            reloadPrepareMoneyFromTransactionIDOld();
            get_vender_bank_account_from_member_by_id();
            get_transaction_get_member_list_of_deposit_money();
            removeCookies("transactions_deposit_id");
            removeCookie("transactions_deposit_id");
          }
        );
      }
    }
  };

  const copyToClipboard = (content: any) => {
    const el = document.createElement("input");
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  const copysubmit = (e: any) => {
    let bankNumberCopy: any = bankVender?.BankNumber ?? "";
    e.target.textContent = "คัดลอกสำเร็จ";
    JSON.stringify(bankNumberCopy);
    copyToClipboard(bankNumberCopy);
    setTimeout(() => {
      e.target.textContent = "คัดลอก";
    }, 2000);
  };

  const showBank = (
    transactionDeposit: GetTransactionGetMemberListOfDepositMoneyRepsonse
  ) => {
    const isBankNewVersion = transactionDeposit.BMBankAccount == null;
    const backgroundColor = isBankNewVersion
      ? BankColor
      : transactionDeposit.BMBankColor;
    const bankAccount = isBankNewVersion
      ? BankAccount
      : transactionDeposit.BMBankAccount;
    const bankName = isBankNewVersion
      ? BankName
      : transactionDeposit.BMBankName;
    const bankImage = isBankNewVersion
      ? BankImage
      : transactionDeposit.BMBankImage;
    const bankNumber = isBankNewVersion
      ? BankNumber
      : transactionDeposit.BankNumber;
    return (
      <div style={{ textAlign: "center" }}>
        <div className="row">
          {" "}
          <div className="col-5">
            <div
              className="row center-row-content"
              style={{
                paddingTop: 5,
              }}
            >
              <div
                className="frame-bank margin-top-10"
                style={{
                  backgroundColor: backgroundColor ?? "",
                }}
              >
                <Img
                  ratio={1 / 1}
                  style={{ height: 70, marginTop: 13 }}
                  src={bankImage ?? ""}
                ></Img>{" "}
              </div>
            </div>
            <label
              className="border-info"
              style={{ padding: "5px 5px 5px 5px", marginTop: 10 }}
            >
              <div style={{ fontSize: 12 }}>{bankAccount}</div>
              <div style={{ fontSize: 12 }}>{bankName}</div>
              <div style={{ fontSize: 14, fontWeight: "bold" }}>
                {bankNumber}
              </div>
            </label>
          </div>
          <div className="col-2" style={{ padding: 0 }}>
            <div
              className="row center-row-content"
              style={{
                marginTop: 15,
              }}
            >
              <TranferIcon></TranferIcon>
            </div>
          </div>
          <div className="col-5">
            <div
              className="row center-row-content"
              style={{
                paddingTop: 5,
              }}
            >
              <div
                className="frame-bank margin-top-10"
                style={{
                  backgroundColor: bankVender?.Color ?? "",
                }}
              >
                <Img
                  ratio={1 / 1}
                  style={{ height: 70, marginTop: 13 }}
                  src={bankVender?.Image ?? ""}
                ></Img>{" "}
              </div>
            </div>
            <label
              className="border-info"
              style={{ padding: "5px 5px 5px 5px", marginTop: 10 }}
            >
              <div style={{ fontSize: 12 }}>{bankVender?.BankAccount}</div>
              <div style={{ fontSize: 12 }}>{bankVender?.BankName}</div>
              <div style={{ fontSize: 14, fontWeight: "bold" }}>
                {bankVender?.BankNumber}
              </div>
            </label>
            <div
              className="row center-row-content"
              style={{ marginTop: 10, marginBottom: 5, width: 110 }}
            >
              <MaxButton
                title="คัดลอก"
                status={StatusMaxButton.bluesubmit}
                onClick={copysubmit}
              ></MaxButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const isDisableButtonCreateDeposit = (amount: number): boolean => {
    if (loading) {
      return true;
    }

    if (promotion != undefined) {
      if ((promotion.Higher ?? 0) < amount) {
        return true;
      }

      if ((promotion.Lower ?? 0) > amount) {
        return true;
      }
    }

    return false;
  };

  const displayPromotion = () => {
    return (
      <>
        {promotion && (
          <div className=" margin-bottom-5">
            <h2>
              <span>{promotion?.Type}</span>
            </h2>
            <div className="row col-12">
              <div
                className="col-5 col-md-2 col-lg-2 col-xl-1"
                style={{
                  marginRight: 0,
                }}
              >
                <Img
                  className="border-radius shadow shine"
                  src={promotion?.Image ?? ""}
                  ratio={0.6}
                  alt={promotion.Type}
                ></Img>
              </div>
              <div className="col-7 col-md-10 col-lg-10 col-xl-11">
                เงื่อนไข
                <li>สูงสุด: {promotion?.Higher?.format(0, 0)} เครดิต</li>
                <li>ขั้นต่ำ: {promotion?.Lower?.format(0, 0)} เครดิต</li>
                <li>อัตรา: {promotion?.Amount?.format(0, 0)} %</li>
                <li>
                  เทิร์นโอเวอร์: {promotion?.FinalAmount?.format(0, 0)} เท่า
                </li>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const FirstDepositStep = () => {
    return (
      <div>
        {!isPrepareTransactionID && transactionDeposit.length == 0 && (
          <>
            {displayPromotion()}
            <LeftHaveNoPrefix
              prefix={"ระบุยอดเงินที่ต้องการฝาก"}
              key="ระบุยอดเงินที่ต้องการฝาก"
              value={inputMoney}
              title="ระบุยอดเงินที่ต้องการฝาก"
              type="number"
              onChange={(e) => {
                setInputMoney(e.target.value);
              }}
            ></LeftHaveNoPrefix>
            <div
              style={{ marginTop: 10 }}
              className="row col-12 center-row-content "
            >
              {moneyInputs.map((value) => (
                <button
                  key={`numberpad${value}`}
                  disabled={isDisableButtonCreateDeposit(value)}
                  className="col-3 btn btn-choose-money"
                  onClick={() => {
                    setInputMoney(`${value}`);
                  }}
                >
                  <div>{value.format(0, 0)}</div>
                  {promotion && <div>+</div>}
                  {promotion && (
                    <div>
                      <span>
                        {((value * (promotion.Amount ?? 0)) / 100).format(2, 0)}
                      </span>{" "}
                    </div>
                  )}
                </button>
              ))}
            </div>
          </>
        )}
        {!isPrepareTransactionID && transactionDeposit.length == 0 && (
          <div className="margin-top-15">
            <MaxButton
              title="ยืนยัน"
              loading={prepareMoneyLoading}
              status={StatusMaxButton.submit}
              onClick={onPrepareSubmitMoney}
            ></MaxButton>
          </div>
        )}
      </div>
    );
  };

  const ChooseBank = () => {
    return (
      <>
        {!chooseBankMember && (
          <div className="row">
            {getinfo.Banks?.map((value, i) => {
              return (
                <div key={i} className="col-12">
                  <div
                    onClick={() => {
                      setChooseBankMember(value);
                    }}
                  >
                    <BankAccountShow
                      bankAccount={value?.Type}
                      bankImage={value?.Image}
                      bankName={value?.BankName}
                      bankColor={value?.Color}
                      bankNumber={value?.BankNumber}
                    ></BankAccountShow>
                  </div>
                </div>
              );
            })}
            {IsCheckMutiBankInModal(getinfo) && (
              <div className="col-12">
                <div
                  className="card-add-bank color-font-card margin-top-15 text-center"
                  style={{ paddingTop: 63, paddingBottom: 63 }}
                  onClick={goToAddBank}
                >
                  <span>+ เพิ่มบัญชีธนาคาร</span>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const PrepareSubmitDeposit = () => {
    return (
      <>
        {
          <div style={{ marginTop: 0 }}>
            <div className="bottom-line-black"></div>
            <div className="text-center margin-top-20 margin-bottom-10">
              โอนตามยอดที่ระบุ
            </div>
            <h2 className="text-center" style={{ marginBottom: 10 }}>
              {amountGen?.format(2, 0)} บาท
            </h2>
          </div>
        }
        <div>
          <MaxButton
            title={"กดปุ่มนี้เมื่อโอนเสร็จเรียบร้อย"}
            onClick={() => {
              onSubmitMoney(cookies.transactions_deposit_id ?? "");
            }}
            loading={loading}
            status={StatusMaxButton.submit}
          ></MaxButton>
        </div>
      </>
    );
  };

  const UIAfterSubmitSendRequestToVender = () => {
    return (
      <>
        {transactionDeposit.map((value, i) => {
          return (
            <div key={i} className="text-center">
              {showBank(value)}
              <div className="bottom-line-black"></div>
              <div className="text-center margin-top-20 margin-bottom-10">
                <Spinner></Spinner> ยอดเงินที่ต้องโอน
              </div>
              <h2 className="text-center">{value.Amount?.format(2, 0)} บาท</h2>
              <div
                className="bottom-line-black"
                style={{ marginTop: 20, marginBottom: 20 }}
              ></div>
              <div className="text-center">
                <div style={{ marginBottom: 10 }}>โอนภายในเวลา</div>
                <Badge bg="danger">
                  <div style={{ fontSize: 20 }}>
                    <Timer></Timer>
                  </div>
                </Badge>
              </div>
              <div
                className="row center-row-content col-12"
                style={{ marginBottom: 20 }}
              >
                <div
                  className="col-12"
                  style={{
                    paddingRight: 0,
                    paddingLeft: 0,
                    marginTop: 20,
                    marginBottom: -30,
                  }}
                >
                  <MaxButton
                    title={"ยกเลิกรายการ"}
                    status={StatusMaxButton.back}
                    onClick={() => {
                      rejectWithMember();
                    }}
                  ></MaxButton>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <Modal
      {...props}
      size="lg"
      scrollable={true}
      contentClassName="view-background-deposit-withdraw"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isDepositModal}
      animation={false}
      onHide={() => {
        removeCookie("transactions_deposit_id");
        removeCookies("promotion_hash_key_editing");
        setChooseBankMember(undefined);
        closeModal();
      }}
      dialogClassName="modal-dialog"
    >
      <Modal.Header
        closeButton
        className="modal-header-box-game btn-close-white"
      ></Modal.Header>
      <Modal.Body>
        <h3
          className="w-100 text-center"
          style={{ fontWeight: "bold", marginTop: -15 }}
        >
          <span>ฝากเงิน</span>
        </h3>
        {transactionDeposit.length == 0 && ChooseBank()}
        {chooseBankMember && FirstDepositStep()}
        {isPrepareTransactionID &&
          transactionDeposit.length == 0 &&
          PrepareSubmitDeposit()}
        {UIAfterSubmitSendRequestToVender()}
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
    getinfo: state.member.getinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModal: () => {
      dispatch({
        type: memberActionTypes.ACTION_DEPOSIT_IS_CLOSE_MODAL,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertWithDeposit);
