import { AppReducerStateProps } from "lib/store";
import React from "react";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";

interface BalanceViewProps {
  state: AppReducerStateProps;
}

function BalanceView(props: BalanceViewProps) {
  return (
    <div className="btn text-left">
      <div className="btn-group">
        <div className="bg-money-balance border-udiamond">
          <div
            style={{
              float: "left",
              width: 30,
              height: 30,
            }}
          >
            <Img ratio={1 / 1} src="icon-tab-cash4.png" alt="เครดิต"></Img>
          </div>
          <div
            style={{
              marginTop: 3,
              marginLeft: 5,
              float: "right",
            }}
          >
            {props.state.member.getinfo.Balance?.format(2, 0)}&nbsp;
          </div>
          <div style={{ clear: "both" }}></div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(BalanceView);
