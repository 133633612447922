import client from "../client";

interface Request {
  prefix: string;
}

export interface HeaderConfigResponse {
  ID?: number;
  VenderID?: number;
  Content?: string;
  CreatedAt?: Date;
  UpdateAt?: Date;
  Link?: string;
  Type?: string;
  LinkPathOnWebsiteID?: number;
  ConfigHeaderTypeID?: number;
}

export default function API(request: Request): Promise<HeaderConfigResponse[]> {
  return client
    .get(`genaral/header_config`, { params: request })
    .then((response) => response.data)
    .then((json) => {
      return json;
    });
}
