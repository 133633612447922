import client from "../client";
import { store } from "../../store";
import { memberActionTypes } from "lib/store/member/reducer";
import update_member_ip_address from "./update_member_ip_address";
import refresh_token from "./refresh_token";
import { getCookie } from "cookies-next";

export interface GetInfoResponse {
  Id?: number;
  Username?: string | null;
  Context?: string | null;
  Balance?: number | null;
  SMPoint?: number | null;
  CurrencyID?: number | null;
  CreatedAt?: Date | null;
  UpdatedAt?: Date | null;
  IsBlocked?: boolean | null;
  CasinoMaxBet?: number | null;
  CasinoMinBet?: number | null;
  BankName?: string | null;
  BankNumber?: string | null;
  IPAddress?: string | null;
  MemberVenderID?: number | null;
  SportMaxBet?: number | null;
  SportMinBet?: number | null;
  SportPerMatch?: number | null;
  BankTypeID?: number | null;
  DisplayCreatedAt?: string | null;
  DisplayUpdatedAt?: string | null;
  CurrencyName?: string | null;
  Country?: string | null;
  BankAccount?: string | null;
  BankColor?: string | null;
  BankImage?: string | null;
  StaftID?: number | null;
  VenderName?: string | null;
  Website?: string | null;
  Rank?: Rank | null;
  Prefix?: string;
  RankingMemberInfomation?: RankingMemberInfomation | null;
  CalculateLevelRankingModel?: CalculateLevelRankingModel | null;
  Banks?: BankByMember[];
  Tel?: string;
  IsMutiBank?: boolean;
}

interface Rank {
  ID?: number;
  MemberID?: number;
  MemberEXP?: number;
  RankTypeID?: number;
  CreatedAt?: Date;
  UpdateAt?: Date;
  RankEXP?: number;
  Com?: number;
  Image?: string;
  Name?: string;
  DisplayCreatedAt?: string;
  DisplayUpdatedAt?: string;
}

interface RankingMemberInfomation {
  ID?: number;
  Exp?: number;
  Name?: string;
  Image?: string;
  COM?: number;
  UpdateAt?: Date;
  CreatedAt?: Date;
}

interface CalculateLevelRankingModel {
  NextUpLvRankingPercentage?: number;
  ExpCurrentRanking?: number;
  ExpNextRanking?: number;
}

export interface BankByMember {
  ID?: number;
  BankName?: string;
  BankNumber?: string;
  BankTypeID?: number;
  Color?: string;
  Image?: string;
  Type?: string;
  CreatedAt?: Date;
  UpdateAt?: Date;
  DisplayCreatedAt?: string;
  DisplayUpdatedAt?: string;
}

export default function API(): Promise<GetInfoResponse> {
  return client
    .get(`members/getinfo`)
    .then((response) => response.data)
    .then((json) => {
      const token = getCookie("refresh_token")?.toString() ?? "";
      refresh_token({ refresh_token: token });
      store.dispatch({
        type: memberActionTypes.ACTION_USER_UPDATE_INFO,
        payload: json,
      });
      update_member_ip_address();
      return json;
    });
}

export function APILoading(): Promise<GetInfoResponse> {
  store.dispatch({
    type: memberActionTypes.ACTION_USER_UPDATE_INFO_IS_LOADING,
    payload: true,
  });

  return client
    .get(`members/getinfo`)
    .then((response) => response.data)
    .then((json) => {
      const token = getCookie("refresh_token")?.toString() ?? "";
      refresh_token({ refresh_token: token });
      store.dispatch({
        type: memberActionTypes.ACTION_USER_UPDATE_INFO,
        payload: json,
      });
      update_member_ip_address();
      return json;
    })
    .finally(() => {
      store.dispatch({
        type: memberActionTypes.ACTION_USER_UPDATE_INFO_IS_LOADING,
        payload: false,
      });
    });
}

interface Request {
  token?: string;
}

export function APIGetMemberInformation(
  request: Request
): Promise<GetInfoResponse> {
  return client
    .get(`members/getinfo`, {
      headers: {
        Authorization: `Bearer ${request?.token ?? ""}`,
      },
    })
    .then((response) => response.data)
    .then((json) => {
      return json;
    });
}
