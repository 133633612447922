import { checkLogin } from "lib/check_login";
import client from "../client";
import get_transaction_favroite_game from "./get_transaction_favroite_game";

export interface InsertTransactionFavoriteGameRequest {
  game_id?: number;
}

export default function API(request: InsertTransactionFavoriteGameRequest) {
  if (!checkLogin()) {
    return Promise.reject({
      response: {
        data: {
          message: "กรุณาเข้าสู่ระบบ",
        },
      },
    });
  }

  return client
    .post(`members/insert_transaction_favorite_game`, request)
    .then((response) => response.data)
    .then(() => {
      return get_transaction_favroite_game();
    });
}
