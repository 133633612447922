import { setCookies } from "cookies-next";
import client from "../client";
import { store } from "../../store";
import { memberActionTypes } from "lib/store/member/reducer";

export interface GetVenderDomainInfomationRequest {
  domain: string;
}

export interface GetVenderDomainInfomationResponse {
  Prefix: string;
  Logo?: string;
}

export default function API(
  request: GetVenderDomainInfomationRequest
): Promise<GetVenderDomainInfomationResponse> {
  return client
    .get(`genaral/get_vender_domain_infomation`, { params: request })
    .then((response) => response.data)
    .then((json: GetVenderDomainInfomationResponse) => {
      setCookies("logo", json.Logo);
      setCookies("prefix", json.Prefix);
      store.dispatch({
        type: memberActionTypes.ACTION_UPDATE_DOMAIN_INFOMATION,
        payload: json,
      });
      return json;
    });
}
