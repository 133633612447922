import { Modal } from "react-bootstrap";

interface ChatImgModalProps {
  closeModal?: Function | undefined;
  isModalChatImage: boolean;
  image?: string | null | undefined;
  onSendImage?: Function | undefined;
}

function ChatSelectImgModal(props: ChatImgModalProps) {
  const { onSendImage, closeModal, image, isModalChatImage } = props;

  return (
    <Modal
      {...props}
      size="lg"
      scrollable={true}
      contentClassName="view-background-chat"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isModalChatImage}
      onHide={() => {
        if (closeModal) {
          closeModal();
        }
      }}
      dialogClassName="modal-chat"
    >
      <Modal.Header closeButton className="modal-header">
        <Modal.Title id="example-modal-sizes-title-lg"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row center-row-content col-12 col-md-6 col-lg-6">
          <img
            style={{ height: "auto", width: "100%", marginTop: 50 }}
            src={image ?? ""}
          ></img>
        </div>
        <button
          style={{ marginTop: 10 }}
          className="btn btn-purple-submit col-12 col-md-6 col-lg-6 center-row-content"
          title="ส่ง"
          onClick={(e) => {
            if (closeModal != undefined) {
              closeModal();
              if (onSendImage != undefined) {
                onSendImage(e);
              }
            }
          }}
        >
          ส่ง
        </button>
        <button
          style={{ marginTop: 10 }}
          className="btn btn-purple-close-chat col-12 col-md-6 col-lg-6 center-row-content"
          title="ปิด"
          onClick={() => {
            if (closeModal != undefined) {
              closeModal();
            }
          }}
        >
          ปิด
        </button>
      </Modal.Body>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
}

export default ChatSelectImgModal;
