import SMPointView from "components/view/smpoint_view";
import { AppReducerStateProps, store } from "lib/store";
import { memberActionTypes } from "lib/store/member/reducer";
import { GetInfoResponse } from "lib/worker/api/getinfo";
import { Badge, Modal, ProgressBar } from "react-bootstrap";
import Img from "react-cloudimage-responsive";
import { connect } from "react-redux";
import { alertWithDeposit } from "./deposit_modal";
import { alertWithWithDraw } from "./withdraw_modal";
import Link from "next/link";
import { prefix } from "lib/worker/client";
import { MaxButton, StatusMaxButton } from "components/view/button";
import BalanceView from "components/view/balance_view";
import CheckOutModal, { alertCheckOutModal } from "./check_out_modal";
import { useEffect, useState } from "react";
import getinfo_non_r from "lib/worker/api/getinfo_non_r";
import apigetinfo from "../../lib/worker/api/getinfo";
import get_status_member from "lib/worker/api/get_status_member";
import get_promotion_remaining, {
  GetPromotionRemaingingResponse,
} from "lib/worker/api/get_promotion_remaining";
import { checkLogin } from "lib/check_login";

interface Props {
  closeModal: Function;
  state: AppReducerStateProps;
  getinfo: GetInfoResponse;
}

export const alertWithMenuModal = () => {
  store.dispatch({
    type: memberActionTypes.ACTION_MENU_IS_OPEN_MODAL,
  });
};

const slides = [
  {
    pic: "icon-tab-history-deposit.png",
    location: "/transaction_transfer",
    alt: "ประวัติฝาก-ถอน",
    title: "ประวัติฝาก-ถอน",
  },
  {
    pic: "icon-tab-play.png",
    location: "/transaction_bet_game",
    alt: "ประวัติการเล่น",
    title: "ประวัติการเล่น",
  },
  {
    pic: "icon-tab-udia.png",
    location: "/transaction_udiamond",
    alt: "ประวัติ uDiamond",
    title: "ประวัติกิจกรรม",
  },
];

const slides2 = [
  {
    pic: "icon-tab-password.png",
    location: "/new_password",
    alt: "เปลี่ยนรหัสผ่าน",
    title: "เปลี่ยนรหัสผ่าน",
  },
  {
    pic: "icon-tab-contact.png",
    location: `/contact/${prefix}`,
    alt: "ติดต่อเรา",
    title: "ติดต่อเรา",
  },
];

const slides3 = [
  {
    pic: "icon-tab-box.png",
    location: "/uGame/open_ubox",
    alt: "เปิดกล่อง",
    title: "เปิดกล่อง",
  },
  {
    pic: "icon-tab-comment.png",
    location: "/comment_all",
    alt: "คอมเม้นหน้าเว็บ",
    title: "คอมเม้นหน้าเว็บ",
  },
  {
    pic: "icon-tab-rps.png",
    location: "/uGame/urps",
    alt: "เป่า ยิ้ง ฉุบ",
    title: "เป่า ยิ้ง ฉุบ",
  },
  {
    pic: "icon-tab-checkin.png",
    location: "/uGame/check_in",
    alt: "เช็คชื่อรับรางวัล",
    title: "เช็คชื่อรับรางวัล",
  },
  {
    pic: "icon-udiamond-new.png",
    location: "/exchange",
    alt: "แลก uDiamond",
    title: "แลก uDiamond",
  },
];

function MenuModal(props: Props) {
  const { closeModal } = props;
  const { isMenuModal } = props.state.member;
  const getinfo: GetInfoResponse = props.getinfo;
  const [_promotionsloading, setpromitonloading] = useState<boolean>(false);
  const [promotionsremaining, setpromotionsremaining] = useState<
    GetPromotionRemaingingResponse[]
  >([]);

  const getinfoo = () => {
    setpromitonloading(true);
    get_status_member()
      .then((json) => {
        return json;
      })
      .then(() => {
        return apigetinfo();
      })
      .finally(() => {});

    get_promotion_remaining()
      .then((v) => {
        setpromotionsremaining(v);
      })
      .finally(() => {
        setpromitonloading(false);
      });
  };

  useEffect(() => {
    if (checkLogin()) {
      getinfoo();
      get_promotion_remaining().then(setpromotionsremaining);
      getinfo_non_r();
    }
  }, [isMenuModal]);

  useEffect(() => {
    if (checkLogin()) {
      let interval = setInterval(function () {
        get_promotion_remaining().then((v) => {
          setpromotionsremaining(v);
          return;
        });
        getinfo_non_r();
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    }
  }, []);

  return (
    <Modal
      show={isMenuModal}
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
      animation={false}
      onHide={() => {
        if (closeModal) {
          closeModal();
        }
      }}
      contentClassName="modal-menu-view"
    >
      <Modal.Header
        closeButton
        className="modal-header-box-game btn-close-white"
      ></Modal.Header>
      <Modal.Body>
        <div className="row" style={{ marginTop: -40 }}>
          <div className="text-center">
            <div className="btn text-left">
              <div className="btn-group">
                <div className="bg-money-balance">
                  <div
                    style={{ float: "left", width: 50, height: 50 }}
                    onClick={() => {
                      closeModal();
                    }}
                  >
                    <Link href="/invite_agent">
                      <Img
                        ratio={1}
                        className="full-width-image margin-top-10 margin-bottom-10"
                        src={getinfo.Rank?.Image ?? ""}
                        alt="ระดับตัวแทน คาสิโนออนไลน์"
                      ></Img>
                    </Link>
                  </div>
                  <div
                    style={{
                      marginTop: 20,
                      marginLeft: 5,
                      fontSize: 20,
                      float: "right",
                    }}
                  >
                    {getinfo.Username}
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="text-center">
            <BalanceView></BalanceView> <SMPointView></SMPointView>
          </div>
        </div>

        {getinfo.BankName == null && (
          <>
            <div className="col-12">
              <Link href="/add_bank">
                <div className="">
                  <MaxButton
                    title="เพิ่มธนาคาร"
                    status={StatusMaxButton.deposit}
                  ></MaxButton>
                </div>
              </Link>
            </div>
          </>
        )}

        <div className="row" style={{ marginTop: -2 }}>
          <div className="col-6">
            <div
              className="btn btn-purple-submit margin-top-10"
              onClick={() => {
                alertWithDeposit();
              }}
            >
              <div
                className=""
                style={{
                  width: 50,
                  height: 50,
                  float: "left",
                }}
              >
                <Img ratio={1} src="icon-modal-deposit3.png" alt="ฝาก"></Img>
              </div>
              <label style={{ float: "left", marginTop: 13, marginLeft: 10 }}>
                ฝากเงิน
              </label>
              <div style={{ clear: "both" }}></div>
            </div>
          </div>
          <div className="col-6">
            <div
              className="btn background-blue-linear margin-top-10"
              onClick={() => {
                alertWithWithDraw();
              }}
            >
              <div
                className=""
                style={{
                  width: 50,
                  height: 50,
                  float: "left",
                }}
              >
                <Img ratio={1} src="icon-modal-withdraw3.png" alt="ฝาก"></Img>
              </div>
              <label style={{ float: "left", marginTop: 13, marginLeft: 10 }}>
                ถอนเงิน
              </label>
              <div style={{ clear: "both" }}></div>
            </div>
          </div>
        </div>

        {promotionsremaining.map((v, i) => {
          return (
            <div
              key={i}
              className="text-center"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Badge style={{ marginBottom: 5 }}>
                ติดโปร | {v.RemainingAmount?.format(2, 0)} /{" "}
                {((v.Amount ?? 0) * (v.FullFinalAmouunt ?? 0))?.format(2, 0)}
              </Badge>
              <div>
                {promotionsremaining.map((v, i) => {
                  return (
                    <ProgressBar key={i} style={{ height: 20, marginTop: 3 }}>
                      <ProgressBar
                        striped
                        variant="success"
                        now={v.ProgressBar ?? 0}
                        key={1}
                        label={`${v.ProgressBar?.format(0, 0)} %`}
                      />
                      <ProgressBar
                        striped
                        variant="danger"
                        now={100 - (v.ProgressBar ?? 0)}
                        key={2}
                      />
                    </ProgressBar>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div style={{ paddingTop: 5 }}>
          <span>ประวัติ</span>
        </div>
        <div className="row wrapper margin-top-10">
          {slides.map((el, i) => {
            return (
              <Link key={i} href={el.location}>
                <div
                  className="col-6 col-md-4 col-lg-3 margin-top-10 grid"
                  style={{ marginTop: 5 }}
                  key={i}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <div
                    className="border-info"
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        float: "left",
                        marginRight: 10,
                      }}
                    >
                      <Img ratio={1} src={el.pic ?? ""} alt={el.alt}></Img>
                    </div>
                    <label>{el.title}</label>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        <div style={{ paddingTop: 10 }}>
          <span>กิจกรรม uDiamond</span>
        </div>
        <div className="row wrapper" style={{ paddingTop: 10 }}>
          {slides3.map((el, i) => {
            return (
              <Link key={i} href={el.location}>
                <div
                  className="col-6 col-md-4 col-lg-3 grid"
                  style={{ marginTop: 5 }}
                  key={i}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <div
                    className="border-info"
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        float: "left",
                        marginRight: 10,
                      }}
                    >
                      <Img ratio={1} src={el.pic ?? ""} alt={el.alt}></Img>
                    </div>
                    <label>{el.title}</label>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        <div style={{ paddingTop: 10 }}>
          <span>ตั้งค่า</span>
        </div>
        <div className="row wrapper" style={{ paddingTop: 10 }}>
          {slides2.map((el, i) => {
            return (
              <Link key={i} href={el.location}>
                <div
                  className="col-6 col-md-4 col-lg-3 grid"
                  style={{ marginTop: 5 }}
                  key={i}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  <div
                    className="border-info"
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 10,
                    }}
                  >
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        float: "left",
                        marginRight: 10,
                      }}
                    >
                      <Img ratio={1} src={el.pic ?? ""} alt={el.alt}></Img>
                    </div>
                    <label>{el.title}</label>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        <div className="row center-row-content col-12">
          <div className="col-6" style={{ paddingLeft: 0 }}>
            <MaxButton
              title="ปิด"
              status={StatusMaxButton.submit}
              onClick={() => {
                closeModal();
              }}
            ></MaxButton>
          </div>
          <div className="col-6" style={{ paddingRight: 0 }}>
            <MaxButton
              title="ออกจากระบบ"
              onClick={() => {
                alertCheckOutModal();
              }}
              status={StatusMaxButton.bluesubmit}
            ></MaxButton>
          </div>
        </div>
        <CheckOutModal></CheckOutModal>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state: AppReducerStateProps) => {
  return {
    state: state,
    getinfo: state.member.getinfo,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeModal: () => {
      dispatch({
        type: memberActionTypes.ACTION_MENU_IS_CLOSE_MODAL,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuModal);
