import Link from "next/link";

function MenuTabBar() {
  return (
    <div>
      <input id="menu__toggle" type="checkbox" />
      <label className="menu__btn" htmlFor="menu__toggle">
        <span></span>
        <label className="text-title-black"></label>
      </label>
      <ul className="menu__box center-col">
        <Link href="/">
          <a className="menu__item">
            <li>หน้าหลัก</li>
          </a>
        </Link>
        <Link href="/download_app">
          <a className="menu__item">
            <li className="row">ดาวน์โหลดแอพพลิเคชั่น UFABET</li>
          </a>
        </Link>
        <Link href="/blogs/all_blog">
          <a className="menu__item">
            <li className="row">บทความ</li>
          </a>
        </Link>
      </ul>
    </div>
  );
}

export default MenuTabBar;
