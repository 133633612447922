import { alertWithMessage } from "components/modal/message_modal";
import { getCookie } from "cookies-next";
import { checkLogin } from "lib/check_login";
import Router from "next/router";
import client, { prefix } from "../client";
import getinfo from "./getinfo";
import get_all_game_promotion_only from "./get_all_game_promotion_only";
import get_promotion_remaining from "./get_promotion_remaining";

export interface StartGameRequest {
  VenderPrepix?: string | undefined;
  Symbol?: string | undefined;
  IsDemo?: boolean | undefined;
  Device?: string | undefined;
}

export default async function API(request: StartGameRequest) {
  if (!request.IsDemo) {
    if (!checkLogin()) {
      alertWithMessage("กรุณาเข้าสู่ระบบ ถึงจะสามารถเล่นเกมได้");
      return;
    }
  }

  const hostBack =
    location.protocol + "//" + location.host + "/" + location.pathname;
  var windowReference: any;

  if (!window.navigator.userAgent.includes("Line")) {
    windowReference = window.open();
  }

  try {
    const promotion = await get_promotion_remaining();
    if (promotion.length != 0) {
      let isPassPromotion = false;
      const memberInfo = await getinfo();
      const gamePromotion = await get_all_game_promotion_only({
        prefix: memberInfo.Prefix ?? "",
      });

      for (let index = 0; index < gamePromotion.length; index++) {
        const element = gamePromotion[index];
        if (element.SymbolOriginal == request.Symbol) {
          isPassPromotion = true;
          break;
        }
      }

      if (!isPassPromotion) {
        alertWithMessage(
          "ไม่สามารถเริ่มเล่นเกมนี้ได้ เนื่องจากเกมที่ท่านเลือกไม่เข้าร่วมกิจกรรมโบนัสเครดิต กรุณาเลือกเล่นเกมที่ทางเราได้จัดเตรียมไว้ให้เท่านั้น"
        );
        if (windowReference != null) {
          windowReference!.close();
        } else {
          Router.back();
        }
        return;
      }
    }

    switch (request.VenderPrepix) {
      case "sa":
        return client
          .get(`games/sagaming/Login`)
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "pp":
        if (request.IsDemo) {
          return client
            .post(`games/pragmatic/start_game_demo`, {
              symbol: request.Symbol,
              is_demo: request.IsDemo ?? false,
            })
            .then((response) => response.data)
            .then((json: any) => {
              if (windowReference != null) {
                windowReference!.location = json;
              } else {
                Router.push(json);
              }
              return json;
            })
            .catch((error) => {
              alertWithMessage(error.response.data.message);
              if (windowReference != null) {
                windowReference!.close();
              } else {
                Router.back();
              }
            });
        } else {
          return client
            .post(`games/pragmatic/start_game`, {
              symbol: request.Symbol,
              is_demo: request.IsDemo ?? false,
            })
            .then((response) => response.data)
            .then((json: any) => {
              if (windowReference != null) {
                windowReference!.location = json;
              } else {
                Router.push(json);
              }

              return json;
            })
            .catch((error) => {
              alertWithMessage(error.response.data.message);
              if (windowReference != null) {
                windowReference!.close();
              } else {
                Router.back();
              }
            });
        }
      case "awcsexy":
        return client
          .post("games/awcsexy/awc_sexy_start_game")
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "jk":
        return client
          .post("/games/joker/launch_game", {
            game_code: request.Symbol,
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "atsygg":
        return client
          .post("/atsygg/launchgame", {
            game_id: request.Symbol,
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "ylfishing":
        return client
          .post("/ylfishing/launch_game", {
            gameId: parseInt(request.Symbol ?? "0"),
            returnURL: hostBack,
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "pg":
        if (request.IsDemo) {
          return client
            .post("/games/pggame/internal/launch_game_trial_game", {
              gameCode: request.Symbol,
              returnURL: hostBack,
            })
            .then((response) => response.data)
            .then((json: any) => {
              if (windowReference != null) {
                windowReference!.location = json;
              } else {
                Router.push(json);
              }
              return json;
            })
            .catch((error) => {
              alertWithMessage(error.response.data.message);
              if (windowReference != null) {
                windowReference!.close();
              } else {
                Router.back();
              }
            });
        } else {
          return client
            .post("/games/pggame/internal/launch_game_real_game", {
              gameCode: request.Symbol,
              returnURL: hostBack,
            })
            .then((response) => response.data)
            .then((json: any) => {
              if (windowReference != null) {
                windowReference!.location = json;
              } else {
                Router.push(json);
              }
              return json;
            })
            .catch((error) => {
              alertWithMessage(error.response.data.message);
              if (windowReference != null) {
                windowReference!.close();
              } else {
                Router.back();
              }
            });
        }
      case "dgf":
        return client
          .post("/games/dragoonSoft/launch_game", {
            game_id: request.Symbol,
            is_demo: request.IsDemo,
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "sbobet":
        return client
          .post("/sbobet/launch_game", { device: request.Device })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "ag":
        return client
          .post("/games/ag/start_game", { device: request.Device })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "lottohot":
        return client
          .post("/lottohot/start_game", {
            device: request.Device,
            prefix: prefix,
            access_token: getCookie("access_token")?.toString(),
            token: getCookie("refresh_token")?.toString(),
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "qm":
        return client
          .post("/games/queenmaker/GameLauncher", {
            device: request.Device,
            code: request.Symbol,
            is_demo: request.IsDemo,
            access_token: getCookie("access_token")?.toString(),
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "jili":
        return client
          .post("/jili/login", {
            device: request.Device,
            game_id: request.Symbol,
            is_demo: request.IsDemo,
            token: getCookie("access_token")?.toString(),
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "jdb":
        return client
          .post("/games/jdb/login", {
            device: request.Device,
            symbol: request.Symbol,
            is_demo: request.IsDemo,
            token: getCookie("access_token")?.toString(),
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "fc":
        return client
          .post("/games/fcagent/login", {
            device: request.Device,
            GameID: request.Symbol,
            is_demo: request.IsDemo,
            token: getCookie("access_token")?.toString(),
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "ebet":
        return client
          .post("/games/ebet/login", {
            device: request.Device,
            GameID: request.Symbol,
            is_demo: request.IsDemo,
            token: getCookie("access_token")?.toString(),
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "bng":
        return client
          .post("/games/bng/login", {
            device: request.Device,
            GameID: request.Symbol,
            is_demo: request.IsDemo,
            token: getCookie("access_token")?.toString(),
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "ufabet":
        return client
          .post("/ufabet/start_lobby", {
            device: request.Device,
            prefix: prefix,
            access_token: getCookie("access_token")?.toString(),
            token: getCookie("refresh_token")?.toString(),
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      case "cq9":
        return client
          .post("/games/cq9/login", {
            device: request.Device,
            prefix: prefix,
            access_token: getCookie("access_token")?.toString(),
            token: getCookie("refresh_token")?.toString(),
          })
          .then((response) => response.data)
          .then((json: any) => {
            if (windowReference != null) {
              windowReference!.location = json;
            } else {
              Router.push(json);
            }
            return json;
          })
          .catch((error) => {
            alertWithMessage(error.response.data.message);
            if (windowReference != null) {
              windowReference!.close();
            } else {
              Router.back();
            }
          });
      default:
    }
  } catch (error: any) {
    alertWithMessage(error.response.data.message);
    if (windowReference != null) {
      windowReference!.close();
    } else {
      Router.back();
    }
  }
}
