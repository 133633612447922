import { GetAllGamesResponseResult } from "lib/worker/api/get_all_game";

export interface MaxTagProps {
  game: GetAllGamesResponseResult | undefined | null;
}

interface MaxTagStatusProps {
  status: StatusMaxTag | null | undefined;
  display: string;
}

export enum StatusMaxTag {
  hot = "flag-hot",
  poppular = "flag-poppular",
  jackpot = "flag-jackpot",
  new = "flag-new",
}

export function MaxTag(props: MaxTagProps) {
  const { game } = props;

  const stateGame = (): MaxTagStatusProps | undefined => {
    if (game?.Jeckpot) {
      return {
        status: StatusMaxTag.jackpot,
        display: "แจ็คพอต",
      };
    }

    if (game?.IsNewGame) {
      return {
        status: StatusMaxTag.new,
        display: "มาใหม่",
      };
    }

    if (game?.Poppular != 0) {
      return {
        status: StatusMaxTag.poppular,
        display: "ยอดฮิต",
      };
    }

    return undefined;
  };

  return (
    <div
      style={{ marginTop: "5px" }}
      className={`tag-game ${stateGame()?.status}`}
    >
      {stateGame()?.display}
    </div>
  );
}
